import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import PlatformSkelet from "../../skeletons/PlatformSkelet";
import { useParams } from "react-router-dom";

const PlatformUpdatesDeleted = () => {
  const { id } = useParams();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [deletedPlatformUpdates, setDeletedPlatformUpdates] = useState([]);
  const [platform, setPlatform] = useState({});

  const navigate = useNavigate();

  const loadDeletedPlatformUpdates = () => {
    setIsDataLoading(true);

    RequestService.get(`platforms/updates/deleted?platform=${id}`, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          const platformUpdates = resp.data.platformUpdates;

          if (platformUpdates.length > 0) {
            setIsDataLoaded(true);
          } else {
            setIsDataLoaded(false);
            setErrMsg("У вас пока нет удалённых обновлений платформы");
          }

          setDeletedPlatformUpdates(platformUpdates);
        } else {
          setIsDataLoaded(false);
          setErrMsg("Произошла ошибка: данные не загружены");
        }

        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
        setIsDataLoaded(false);
        setErrMsg("Произошла ошибка: данные не загружены");
      });
  };

  const onRestore = (id) => {
    RequestService.get(`platforms/updates/restore/${id}`, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    }).then((resp) => {
      if (resp.status === "success") {
        loadDeletedPlatformUpdates();
      }
    });
  };

  const loadPlatform = (setParams = true) => {
    setIsDataLoading(true);

    let endpoint = `platforms/${id}`;

    RequestService.get(endpoint, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          console.log(resp.data);
          const platform = resp.data.platform;
          setIsDataLoaded(true);
          setPlatform(platform);
        } else {
          setIsDataLoaded(false);
          setErrMsg("Произошла ошибка: данные не загружены");
        }

        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
        setIsDataLoaded(false);
        setErrMsg("Произошла ошибка: данные не загружены");
      });
  };

  useEffect(() => {
    loadDeletedPlatformUpdates();
    loadPlatform();
  }, []);

  return (
    <>
      <Helmet>
        <title>Удалённые обновления платформы</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pb: 1 }}>
            <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
              Назад
            </Button>
          </Box>
          {isDataLoading ? (
            <PlatformSkelet />
          ) : isDataLoaded ? (
            <Box sx={{ pt: 3 }}>
              <Card>
                <Box sx={{ py: 2, pl: 2 }}>
                  <Typography>
                    Удаленные обновления платформы {platform.name || ""}
                  </Typography>
                </Box>
                <Divider />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Название</TableCell>
                      <TableCell>Описание</TableCell>
                      <TableCell>Дата</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deletedPlatformUpdates.map((update, ind) => (
                      <TableRow hover key={update._id}>
                        <TableCell>{update._id}</TableCell>
                        <TableCell>
                          {`${platform.name} ${update.version}`}
                        </TableCell>
                        <TableCell
                          style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            maxWidth: 300,
                          }}
                        >
                          {update.text.split("\n").map((p) => {
                            return (
                              <div
                                style={{
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                }}
                              >
                                {p}
                              </div>
                            );
                          })}
                        </TableCell>
                        <TableCell>
                          {new Date(update.createdAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Box sx={{ ml: 1 }}>
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() => onRestore(update._id)}
                              >
                                Восстановить
                              </Button>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </Box>
          ) : (
            <Typography variant="h3" style={{ marginTop: "14px" }}>
              {errMsg}
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PlatformUpdatesDeleted;
