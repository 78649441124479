import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
  CircularProgress,
  Container,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Helmet from "react-helmet";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useNavigate } from "react-router-dom";

const PaymentAdd = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    amount: "",
  });
  const [errors, setErrors] = useState({
    amount: false,
  });

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [projectsData, setProjectsData] = useState([]);
  const [chosenProjectId, setChosenProjectId] = useState("");
  const currecies = ["$", "₽", "₴"];

  const [chosenCurrency, setChosenCurrency] = useState("₽");
  const [errMsg, setErrMsg] = useState("");

  const [alert, setAlert] = useState({
    txt: "",
    isVisible: false,
    type: "error",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: false,
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true,
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false,
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    const isNumb = (inp) => {
      let res = true;

      for (let i = 0; i < inp.length; i++) {
        if (!((inp[i] >= "0" && inp[i] <= "9") || inp[i] === ".")) {
          res = false;
          break;
        }
      }

      return res;
    };

    // if (values.amount.trim() === "" || !isNumb(values.amount.trim())) {
    //   validComplete = false;
    //   formErrors.amount = true;
    // }

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    let vals = { ...values };

    for (let key in vals) {
      vals[key] = "";
    }

    setValues(vals);
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      const payload = {
        amount: values.amount,
        project: chosenProjectId,
        currency: chosenCurrency,
      };

      RequestService.post(
        "payments",
        {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        payload
      )
        .then((resp) => {
          if (resp.status === "success") {
            showAlert("success", "Платёж добавлен");
            clearForm();
          } else {
            showAlert("error", resp.message);
          }

          setIsShowLoader(false);
        })
        .catch((err) => {
          showAlert("error", "Ошибка на сервере");
          setIsShowLoader(false);
        });
    }
  };

  useEffect(() => {
    setIsShowLoader(true);

    RequestService.get("projects?limit=0&autoRenewal=false", {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          const projects = resp.data.projects.filter(
            (project) => !project.isDeleted
          );

          if (projects.length > 0) {
            setChosenProjectId(projects[0]._id);

            setProjectsData(projects.filter(project => project.tariff));
          } else {
            setErrMsg("Сначала добавьте проект");
          }
        } else {
          setErrMsg("Произошла ошибка");
        }

        setIsShowLoader(false);
      })
      .catch((err) => {
        setIsShowLoader(false);
        setErrMsg("Произошла ошибка");
      });
  }, []);

  useEffect(() => {
    const project = projectsData.find((value) => value._id === chosenProjectId);
    const amount = project?.tariffPrice || "0";
    setValues((prev) => {
      return { ...prev, amount };
    });
  }, [chosenProjectId, projectsData, isShowLoader]);

  return (
    <>
      <Helmet>
        <title>Добавление тарифа</title>
      </Helmet>
      <Box sx={{ py: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Container>
      </Box>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          {isShowLoader && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ffffff",
                zIndex: 5,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {projectsData.length > 0 ? (
            <form>
              <Card>
                <CardHeader title="Добавить платёж" />
                <Divider />
                <CardContent sx={{ position: "relative" }}>
                  <TextField
                    disabled={true}
                    fullWidth
                    label="Сумма"
                    margin="normal"
                    name="amount"
                    onChange={handleChange}
                    type="text"
                    value={values.amount}
                    variant="outlined"
                    error={errors.amount}
                    helperText={errors.amount && "Введите число"}
                  />
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="project">Проект</InputLabel>
                    <Select
                      labelId="project"
                      name="project"
                      value={chosenProjectId}
                      label="Проект"
                      onChange={(evt) => {
                        setChosenProjectId(evt.target.value);
                      }}
                    >
                      {projectsData.map((item, ind) => {
                        return (
                          <MenuItem value={item._id} key={ind}>
                            {item.name}
                            {item.tariffrmName && ` / ${item.tariffrmName}`}
                            {item.tariffPrice && ` / ${item.tariffPrice}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="currency">Валюта</InputLabel>
                    <Select
                      labelId="currency"
                      name="currency"
                      value={chosenCurrency}
                      label="Валюта"
                      onChange={(evt) => setChosenCurrency(evt.target.value)}
                      disabled={true}
                    >
                      {currecies.map((item, ind) => {
                        return (
                          <MenuItem value={item} key={ind}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? "flex" : "none" }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          ) : (
            <Typography variant="h2">{errMsg}</Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PaymentAdd;
