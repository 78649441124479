import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Input
} from '@material-ui/core';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';

const AccountProfile = (props) => {
  const [uploadedAvatar, setUploadedAvatar] = useState('');
  const [userAvatar, setUserAvatar] = useState('');
  const [userLogin, setUserLogin] = useState('');

  const uploadNewAvatar = () => {
    if (uploadedAvatar !== '') {
      let data = new FormData();
      data.append('avatar', uploadedAvatar);
  
      RequestService.put('api/user', {
        Authorization: `Bearer ${TokenStorage.getAccessToken()}`
      }, data, false)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 'success') {
          setUserAvatar(`/uploads/${resp.data.user.avatar}`);
        }
      });
    }
  };

  const avaUploaded = (event) => {
    setUploadedAvatar(event.target.files[0]);
  };

  useEffect(() => {
    // RequestService.get('api/user', {
    //   Authorization: `Bearer ${TokenStorage.getAccessToken()}`
    // })
    // .then((resp) => {
    //   if (resp.status === 'success') {
    //     let userData = resp.data.user;
    //     setUserAvatar(`/uploads/${userData.avatar}`);
    //     setUserLogin(userData.login);
    //   }
    // });
  }, []);

  useEffect(() => {
    uploadNewAvatar();
  }, [uploadedAvatar]);

  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            src={userAvatar}
            sx={{
              height: 100,
              width: 100
            }}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {userLogin}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Box style={{ width: '100%' }}>
          <Input accept="image/*" type="file" style={{ display: 'none' }} id="userAvatarUpload" onChange={avaUploaded} />
          <label htmlFor="userAvatarUpload">
            <Button variant="text" style={{ marginTop: '7px' }} fullWidth component="span">
              Upload picture
            </Button>
          </label>
        </Box>
      </CardActions>
    </Card>
  )
};

export default AccountProfile;
