import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useConfirm } from '../../components/confirm';

const UserList = () => {
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const [filterRole, setFilterRole] = useState('');
    const [filterLogin, setFilterLogin] = useState('');
    const [filterEmail, setFilterEmail] = useState('');
    const [filterId, setFilterId] = useState('');

    const [curSearchVal, setCurSearchVal] = useState('Role');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const confirm = useConfirm();

    const loadUsers = (setParams = true) => {
        setIsDataLoading(true);

        let endpoint = `users?page=${page + 1}&limit=${limit}`;

        if (setParams) {
            switch (curSearchVal) {
                case 'Role':
                    if (filterRole !== '') {
                        endpoint = `users?role=${filterRole}`;
                    }
                break;

                case 'Login':
                    if (filterLogin !== '') {
                        endpoint = `users?login=${filterLogin}`;
                    }
                break;

                case 'Email':
                    if (filterEmail !== '') {
                        endpoint = `users?email=${filterEmail}`;
                    }
                break;

                case 'Id':
                    if (filterId !== '') {
                        endpoint = `users?_id=${filterId}`;
                    }
                break;
            }
        }

        RequestService.get(endpoint, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                setUsers(resp.data.users);
                setCount(resp.total);
            }
            setIsDataLoading(false);
        })
        .catch((err) => {
            setIsDataLoading(false);
        });
    };

    const onDelete = (id) => {
        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить пользователя?',
            onConfirm: () => {
                RequestService.delete(`users/${id}`, {
                    Authorization: `Bearer ${TokenStorage.getAccessToken()}`
                })
                .then((resp) => {
                    if (resp.status === 'success') {
                        loadUsers();
                    }
                });
            }
        });
    };

    const searchInputChange = (newVal) => {
        setCurSearchVal(newVal);

        setFilterRole('');
        setFilterLogin('');
        setFilterEmail('');
        setFilterId('');

        if (filterRole !== '' || filterLogin !== '' || filterEmail !== '' || filterId !== '') {
            loadUsers(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterRole = (event) => {
        setFilterRole(event.target.value);

        if (event.target.value === '') {
            loadUsers(false);
        }
    };

    const handleFilterLogin = (event) => {
        setFilterLogin(event.target.value);

        if (event.target.value === '') {
            loadUsers(false);
        }
    };

    const handleFilterEmail = (event) => {
        setFilterEmail(event.target.value);

        if (event.target.value === '') {
            loadUsers(false);
        }
    };

    const handleFilterId = (event) => {
        setFilterId(event.target.value);

        if (event.target.value === '') {
            loadUsers(false);
        }
    };

    const handleFilter = () => {
        loadUsers();
    }

    useEffect(() => {
        loadUsers();
    }, [page, limit]);

    return (
        <>
            <Helmet>
                <title>Пользователи</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet />
                            :
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box sx={{ marginLeft: 2 }}>
                                        <Link to="/app/users/add">
                                            <Button color="primary" variant="contained">
                                                Добавить пользователя
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{ pt: 3 }}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{ minWidth: 1050 }}>

                                                <Box sx={{ pt: 1, pb: 2, px: 2 }}>
                                                    <Box>
                                                        <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                                            <InputLabel id="searchType">По какому полю фильтровать список</InputLabel>
                                                            <Select
                                                              labelId="searchType"
                                                              name="searchValue"
                                                              value={curSearchVal}
                                                              label="По какому полю фильтровать список"
                                                              onChange={(evt) => searchInputChange(evt.target.value)}
                                                            >
                                                                <MenuItem value="Role">Роль</MenuItem>
                                                                <MenuItem value="Login">Логин</MenuItem>
                                                                <MenuItem value="Email">Email</MenuItem>
                                                                <MenuItem value="Id">Id</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>

                                                    <Box sx={{
                                                                mt: 1,
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}>
                                                        <TextField label="Роль"
                                                                name="filterRole"
                                                                onChange={handleFilterRole}
                                                                type="text"
                                                                value={filterRole}
                                                                variant="outlined"
                                                                fullWidth
                                                                sx={{ mr: 2, display: curSearchVal === 'Role' ? 'block' : 'none' }}
                                                        />

                                                        <TextField label="Логин"
                                                                name="filterLogin"
                                                                onChange={handleFilterLogin}
                                                                type="text"
                                                                value={filterLogin}
                                                                variant="outlined"
                                                                fullWidth
                                                                sx={{ mr: 2, display: curSearchVal === 'Login' ? 'block' : 'none' }}
                                                        />

                                                        <TextField label="Email"
                                                                name="filterEmail"
                                                                onChange={handleFilterEmail}
                                                                type="text"
                                                                value={filterEmail}
                                                                variant="outlined"
                                                                fullWidth
                                                                sx={{ mr: 2, display: curSearchVal === 'Email' ? 'block' : 'none' }}
                                                        />

                                                        <TextField label="Id"
                                                                name="filterId"
                                                                onChange={handleFilterId}
                                                                type="text"
                                                                value={filterId}
                                                                variant="outlined"
                                                                fullWidth
                                                                sx={{ mr: 2, display: curSearchVal === 'Id' ? 'block' : 'none' }}
                                                        />

                                                        <Button
                                                          color="primary"
                                                          variant="contained"
                                                          size="large"
                                                          onClick={handleFilter}
                                                        >
                                                            Применить
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                                Аватар
                                                            </TableCell>
                                                            <TableCell>
                                                                Email
                                                            </TableCell>
                                                            <TableCell>
                                                                Логин
                                                            </TableCell>
                                                            <TableCell>
                                                                Роль
                                                            </TableCell>
                                                            <TableCell style={{ overflow: 'hidden' }}>
                                                                Имя
                                                            </TableCell>
                                                            <TableCell>
                                                                Фамилия
                                                            </TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {users.map((user, ind) => (
                                                            <TableRow hover key={ind}>
                                                                <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                                    {user._id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                                                        <Avatar src={user.avatar} />
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.email}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.login}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.role}
                                                                </TableCell>
                                                                <TableCell style={{ maxWidth: '150px', overflow: 'hidden' }}>
                                                                    {user.name || '---'}
                                                                </TableCell>
                                                                <TableCell style={{ maxWidth: '150px', overflow: 'hidden' }}>
                                                                    {user.sureName || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                        <Link to={`/app/user/${user._id}`}>
                                                                            <Button
                                                                              color="primary"
                                                                              variant="contained"
                                                                              size="small"
                                                                            >
                                                                                Инфо
                                                                            </Button>
                                                                        </Link>
                                                                        <Box sx={{ ml: 1 }}>
                                                                            <Link to={`/app/user/edit/${user._id}`}>
                                                                                <Button
                                                                                  color="primary"
                                                                                  variant="contained"
                                                                                  size="small"
                                                                                >
                                                                                    Ред.
                                                                                </Button>
                                                                            </Link>
                                                                        </Box>
                                                                        <Box sx={{ ml: 1 }}>
                                                                            <Button
                                                                              color="primary"
                                                                              variant="contained"
                                                                              size="small"
                                                                              onClick={() => onDelete(user._id)}
                                                                            >
                                                                                Удалить
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                              colSpan={7}
                                                              count={count}
                                                              rowsPerPage={limit}
                                                              page={page}
                                                              onPageChange={handleChangePage}
                                                              onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    )
};

export default UserList;
