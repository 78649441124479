import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TableFooter,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useConfirm } from "../../components/confirm";
import PerfectScrollbar from "react-perfect-scrollbar";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import PlatformSkelet from "../../skeletons/PlatformSkelet";

const PaymentList = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [payments, setPayments] = useState([]);

  const confirm = useConfirm();

  const [filterVals, setFilterVals] = useState({
    id: "",
    projectId: "",
    payerId: "",
  });

  const [curSearchVal, setCurSearchVal] = useState("Id");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const changeValue = (evt) => {
    setFilterVals({
      ...filterVals,
      [evt.target.name]: evt.target.value,
    });

    if (evt.target.value === "") {
      loadPayments(false);
    }
  };

  const loadPayments = (setParams = true) => {
    setIsDataLoading(true);

    let endpoint = `payments?page=${page + 1}&limit=${limit}`;

    if (setParams) {
      switch (curSearchVal) {
        case "Id":
          if (filterVals.id !== "") {
            endpoint = `payments?_id=${filterVals.id}`;
          }
          break;

        case "Project id":
          if (filterVals.projectId !== "") {
            endpoint = `payments?project=${filterVals.projectId}`;
          }
          break;

        case "Payer id":
          if (filterVals.payerId !== "") {
            endpoint = `payments?payer=${filterVals.payerId}`;
          }
          break;
      }
    }

    endpoint += "&sort=-createdAt&isDeleted=false";
    RequestService.get(endpoint, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          const payments = resp.data.payments;
          setIsDataLoaded(true);
          setPayments(payments);
          setCount(resp.total);
        } else {
          setIsDataLoaded(false);
          setErrMsg("Произошла ошибка: данные не загружены");
        }

        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
        setIsDataLoaded(false);
        setErrMsg("Произошла ошибка: данные не загружены");
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleFilter = () => {
    loadPayments();
  };

  const searchInputChange = (newVal) => {
    setCurSearchVal(newVal);

    setFilterVals({
      id: "",
      projectId: "",
      payerId: "",
    });

    let isAnyFilter = false;

    Object.values(filterVals).forEach((value) => {
      if (value !== "") {
        isAnyFilter = true;
      }
    });

    if (isAnyFilter) {
      loadPayments(false);
    }
  };

  const onDelete = (id) => {
    confirm({
      title: "Удаление",
      content: "Вы уверены, что хотите удалить платеж?",
      onConfirm: () => {
        RequestService.delete(`payments/${id}`, {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
        }).then((resp) => {
          if (resp.status === "success") {
            loadPayments();
          }
        });
      },
    });
  };

  useEffect(() => {
    loadPayments();
  }, [page, limit]);

  return (
    <>
      <Helmet>
        <title>Платежи</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          {isDataLoading ? (
            <PlatformSkelet />
          ) : (
            <>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box>
                  <Link to="/app/payments/add">
                    <Button color="primary" variant="contained">
                      Добавить платёж
                    </Button>
                  </Link>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Link to="/app/payments/deleted">
                    <Button color="primary" variant="contained">
                      Удаленные платежи
                    </Button>
                  </Link>
                </Box>
              </Box>
              {isDataLoaded ? (
                <Box sx={{ pt: 3 }}>
                  <Card>
                    <PerfectScrollbar>
                      <Box sx={{ pt: 1, pb: 2, px: 2 }}>
                        <Box>
                          <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                            <InputLabel id="searchType">
                              По какому полю фильтровать список
                            </InputLabel>
                            <Select
                              labelId="searchType"
                              name="searchValue"
                              value={curSearchVal}
                              label="По какому полю фильтровать список"
                              onChange={(evt) =>
                                searchInputChange(evt.target.value)
                              }
                            >
                              <MenuItem value="Id">Id</MenuItem>
                              <MenuItem value="Project id">Id проекта</MenuItem>
                              <MenuItem value="Payer id">
                                Id плательщика
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                        <Box
                          sx={{
                            mt: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            label="Id"
                            name="id"
                            onChange={changeValue}
                            type="text"
                            value={filterVals.id}
                            variant="outlined"
                            fullWidth
                            sx={{
                              mr: 2,
                              display: curSearchVal === "Id" ? "block" : "none",
                            }}
                          />

                          <TextField
                            label="Id проекта"
                            name="projectId"
                            onChange={changeValue}
                            type="text"
                            value={filterVals.projectId}
                            variant="outlined"
                            fullWidth
                            sx={{
                              mr: 2,
                              display:
                                curSearchVal === "Project id"
                                  ? "block"
                                  : "none",
                            }}
                          />

                          <TextField
                            label="Id плательщика"
                            name="payerId"
                            onChange={changeValue}
                            type="text"
                            value={filterVals.payerId}
                            variant="outlined"
                            fullWidth
                            sx={{
                              mr: 2,
                              display:
                                curSearchVal === "Payer id" ? "block" : "none",
                            }}
                          />

                          <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleFilter}
                          >
                            Применить
                          </Button>
                        </Box>
                      </Box>
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Проект</TableCell>
                            <TableCell>Плательщик</TableCell>
                            <TableCell>Сумма</TableCell>
                            <TableCell>Дата</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {payments.map((payment, ind) => (
                            <TableRow hover key={ind}>
                              <TableCell
                                style={{
                                  width: "130px",
                                  minWidth: "90px",
                                  wordBreak: "break-all",
                                }}
                              >
                                {payment._id}
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "170px",
                                  wordBreak: "break-all",
                                }}
                              >
                                {payment.projectmName}
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "170px",
                                  wordBreak: "break-all",
                                }}
                              >
                                {payment.payer}
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "130px",
                                  wordBreak: "break-all",
                                }}
                              >
                                {payment.amount} {payment.currency}
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: "130px",
                                  wordBreak: "break-all",
                                }}
                              >
                                {new Date(payment.createdAt).toLocaleString()}
                              </TableCell>
                              <TableCell>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Box sx={{ ml: 1 }}>
                                    
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={()=>onDelete(payment._id)}
                                      >
                                        Удалить
                                      </Button>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </PerfectScrollbar>
                  </Card>
                </Box>
              ) : (
                <Typography variant="h3" style={{ marginTop: "14px" }}>
                  {errMsg}
                </Typography>
              )}
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PaymentList;
