import { useState } from 'react';
import {
    Alert,
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Input
} from '@material-ui/core';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';

const UserFormCreate = () => {
    const [values, setValues] = useState({
        email: '',
        login: '',
        password: '', 
        name: '',
        surname: '',
        avatar: ''
    });
    const [errors, setErrors] = useState({
      email: false,
      login: false,
      password: false,
      name: false,
      surname: false
    });
    const [isValidateEmail, setIsValidateEmail] = useState(true);
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [isAvaLoaded, setIsAvaLoaded] = useState(false);
    const [uploadedImgName, setUploadedImgName] = useState('');

    const [role, setRole] = useState('Developer');

    const [alert, setAlert] = useState({
      txt: '',
      isVisible: false,
      type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
          ...errors,
          [event.target.name]: false
        });
    };

    const handleChangeEmail = (event) => {
        const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        setIsValidateEmail(!!event.target.value.match(reg));
        setValues({
            ...values,
            email: event.target.value
        });
    };

    const showAlert = (type, text) => {
      setAlert({
        txt: text,
        type,
        isVisible: true
      });

      setTimeout(() => {
        setAlert({
          txt: text,
          type,
          isVisible: false
        });

        setSubmitDisabled(false);
      }, 1400);
    };

    const avaUploaded = (event) => {
      let pathParts = event.target.value.split('\\');
      setUploadedImgName(pathParts[pathParts.length-1]);
      setIsAvaLoaded(true);
      setValues({
        ...values,
        avatar: event.target.files[0]
      });
    }

    const validate = () => {
      let validComplete = true;
      let formErrors = {...errors};

      if (values.login.trim() === '') {
        validComplete = false;
        formErrors.login = true;
      } 

      if (!isValidateEmail || values.email.trim() === '') {
        validComplete = false;
        formErrors.email = true;
        setIsValidateEmail(false);
      }

      if (values.password.trim() === '' || values.password.trim().length < 5) {
        validComplete = false;
        formErrors.password = true;
      } 

      setErrors(formErrors);
      return validComplete;
    };

    const clearForm = () => {
      let vals = {...values};

      for (let key in vals) {
        vals[key] = '';
      }

      setValues(vals);
    };

    const submit = async () => {
      if (validate()) {
        setIsShowLoader(true);
        setSubmitDisabled(true);

        const payload = {
          login: values.login,
          email: values.email,
          password: values.password,
          role: role.toLowerCase()
        };

        if (values.name !== '') {
          payload.name = values.name;
        }

        if (values.surname !== '') {
          payload.sureName = values.surname;
        }

        if (values.avatar !== '') {
          payload.avatar = values.avatar;
        }

        let data = new FormData();
        for (let key in payload) {
          data.append(key, payload[key]);
        }

        RequestService.post('users', {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        }, data, false)
        .then((resp) => {

          console.log(resp);

          if (resp.status === 'success') {
            showAlert('success', 'Пользователь добавлен');
            clearForm();
          } else {
            showAlert('error', resp.message);
  
            // if (resp.message === 'Пользователь с таким login уже существует') {
            //   showAlert('error', 'User with such login already exists');
            // } else if (resp.message === 'Пользователь с таким email уже существует') {
            //   showAlert('error', 'User with such email already exists');
            // } else if (resp.message === 'Переданы неверные данные') {
            //   showAlert('error', 'You passed invalid data');
            // } else {
            //   showAlert('error', 'Error occured');
            // }
          }
  
          setIsShowLoader(false);
        })
        .catch((err) => {
          showAlert('error', 'Ошибка на сервере');
          setIsShowLoader(false);
        });
      }
    };

    return (
      <form>
        <Card>
          <CardHeader
            title="Добавить пользователя"
          />
          <Divider />
          <CardContent sx={{ position: 'relative' }}>
              {isShowLoader && (
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#ffffff',
                  zIndex: 5
                  }}
                >
                    <CircularProgress />
                </Box>
              )}
              <Typography color="textPrimary" variant="h4">Avatar</Typography>
              <Box>
                <input accept=".png, .jpg, .jpeg, .webp" type="file" style={{ display: 'none' }} id="userAvatarUpload" onChange={avaUploaded} />
                <label htmlFor="userAvatarUpload">
                  <Button variant="contained" style={{ marginTop: '7px' }} component="span">
                      Upload
                  </Button>
                </label>
                <Typography
                  style={{
                    display: isAvaLoaded ? 'inline' : 'none', 
                    marginLeft: '8px', 
                    position: 'relative', 
                    top: '3.5px' 
                  }}>
                  {uploadedImgName}
                </Typography>
              </Box>
              <TextField
                fullWidth
                label="Логин *"
                margin="normal"
                name="login"
                onChange={handleChange}
                type="text"
                value={values.login}
                variant="outlined"
                error={errors.login}
              />
              <TextField
                fullWidth
                label="Email *"
                margin="normal"
                name="email"
                onChange={handleChangeEmail}
                type="email"
                value={values.email}
                variant="outlined"
                error={!isValidateEmail}
              />
              <TextField
                fullWidth
                label="Пароль *"
                margin="normal"
                name="password"
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                error={errors.password}
                helperText={errors.password && 'Пароль должен быть длиннее 4-х символов'}
              />
              <TextField
                fullWidth
                label="Имя"
                margin="normal"
                name="name"
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Фамилия"
                margin="normal"
                name="surname"
                onChange={handleChange}
                type="text"
                value={values.surname}
                variant="outlined"
              />
              <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                <InputLabel id="userRole">Role</InputLabel>
                <Select
                  labelId="userRole"
                  name="role"
                  value={role}
                  label="Role"
                  onChange={(evt) => setRole(evt.target.value)}
                >
                  <MenuItem value="Developer">Developer</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                </Select>
              </FormControl>
              <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                {alert.txt}
              </Alert>
          </CardContent>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={submit}
                disabled={submitDisabled}
              >
                  Добавить
              </Button>
          </Box>
        </Card>
      </form>
    );
};

export default UserFormCreate;
