import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
    Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import parseServerDate from '../../API/parseServerDate';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    wrapAvatar: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex'
    },
    wrapInner: {
        flexDirection: 'row',
        display: 'flex',
    },
    label: {
        width: 280,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: 10,
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto'
    },
    panel: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#5664d21c'
    },
    panelInCareington: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#79b3ff1c',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    }
});

const Log = () => {
    const { id } = useParams();

    const [log, setLog] = useState({});
    const [requestError, setRequestError] = useState(false);

    const styles = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        RequestService.get(`logs/${id}`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                setLog(resp.data.log);
            } else {
                setRequestError(true);
            }
        })
        .catch((err) => {
            setRequestError(true);
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Лог</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Card>
                        <CardContent sx={{ p: 3 }}>
                            <Alert severity="error" style={{ display: requestError ? 'flex' : 'none', marginBottom: '16px' }}>
                                Ошибка загрузки данных
                            </Alert>
                            <div className={styles.wrapAvatar}>
                                <div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Id:
                                            </div>
                                            <div className={styles.text}>
                                                {log._id || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Тип операции:
                                            </div>
                                            <div className={styles.text}>
                                                {log.type || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Источник:
                                            </div>
                                            <div className={styles.text}>
                                                {log.targetName || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Id источника:
                                            </div>
                                            <div className={styles.text}>
                                                {log.targetId || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Исполнитель:
                                            </div>
                                            <div className={styles.text}>
                                                {log.executor || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Авто:
                                            </div>
                                            <div className={styles.text}>
                                                {log.isAutomatic ? 'Да' : 'Нет'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Время:
                                            </div>
                                            <div className={styles.text}>
                                                {parseServerDate(log.createdAt)}
                                            </div>
                                        </div>
                                        {
                                            log.details ?
                                                <>
                                                    <div className={styles.wrap}>
                                                        <div className={styles.label}>
                                                            Изменение значения (с / до):
                                                        </div>
                                                        <div className={styles.text}>
                                                            {
                                                                log.details.map((item) => {
                                                                    return <div style={{ marginBottom: '12px' }}>
                                                                                {item.filed} ({item.from} / {item.to})
                                                                            </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Log;
