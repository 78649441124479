import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import PlatformSkelet from '../../skeletons/PlatformSkelet';

const ProjectsDeleted = () => {
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [deletedProjects, setDeletedProjects] = useState([]);

    const navigate = useNavigate();

    const loadDeletedProjects = () => {
        setIsDataLoading(true);

        RequestService.get('projects/deleted', {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                const projects = resp.data.projects;

                if (projects.length > 0) {
                    setIsDataLoaded(true);
                    setDeletedProjects(projects);
                } else {
                    setIsDataLoaded(false);
                    setErrMsg('У вас пока нет удалённых проектов');
                }

            } else {
                setIsDataLoaded(false);
                setErrMsg('Произошла ошибка: данные не загружены');
            }

            setIsDataLoading(false);
        })
        .catch((err) => {
            setIsDataLoading(false);
            setIsDataLoaded(false);
            setErrMsg('Произошла ошибка: данные не загружены');
        });
    };

    const onRestore = (id) => {
        RequestService.get(`projects/restore/${id}`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                loadDeletedProjects();
            }
        });
    };

    useEffect(() => {
        loadDeletedProjects();
    }, []);

    return (
        <>
            <Helmet>
                <title>Удалённые проекты</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Box>
                        <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                            Назад
                        </Button>
                    </Box>
                    {
                        isDataLoading ?
                            <PlatformSkelet />
                            :
                            isDataLoaded ?
                                <Box sx={{ pt: 2 }}>
                                    <Card>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Название
                                                    </TableCell>
                                                    <TableCell>
                                                        Тариф
                                                    </TableCell>
                                                    <TableCell>
                                                        Платформа
                                                    </TableCell>
                                                    <TableCell>
                                                        Пуб. ключ
                                                    </TableCell>
                                                    <TableCell>
                                                        Внутренний
                                                    </TableCell>
                                                    <TableCell>
                                                        Активный
                                                    </TableCell>
                                                    <TableCell>
                                                        Статус
                                                    </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {deletedProjects.map((project, ind) => (
                                                    <TableRow hover key={ind}>
                                                        <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                            {project._id}
                                                        </TableCell>
                                                        <TableCell style={{ maxWidth: '170px', wordBreak: 'break-all' }}>
                                                            {project.name}
                                                        </TableCell>
                                                        <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                            {project.tariff}
                                                        </TableCell>
                                                        <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                            {project.platform}
                                                        </TableCell>
                                                        <TableCell style={{ width: '190px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                            {project.publicKey}
                                                        </TableCell>
                                                        <TableCell>
                                                            {project.autoRenewal ? 'Да' : 'Нет'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {project.isActive ? 'Да' : 'Нет'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {project.status}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Box sx={{ ml: 1 }}>
                                                                    <Button
                                                                      color="primary"
                                                                      variant="contained"
                                                                      size="small"
                                                                      onClick={() => onRestore(project._id)}
                                                                    >
                                                                        Восстановление
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Card>
                                </Box>
                                :
                                <Typography variant="h3" style={{ marginTop: '14px' }}>
                                    {errMsg}
                                </Typography>
                    }
                </Container>
            </Box>
        </>
    )
};

export default ProjectsDeleted;
