import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Alert,
    Typography,
    Input
} from '@material-ui/core';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import firstLetterToUpperCase from '../../API/firstLetterToUpperCase';

const UserFormEdit = (props) => {
    const { id } = props;
    const [values, setValues] = useState({
      login: '',
      email: '',
      name: '',
      surname: '',
      avatar: ''
    });
    const [errors, setErrors] = useState({
      login: false,
      email: false
    });

    const [emailBeforeChange, setEmailBeforeChange] = useState({});
    const [isValidateEmail, setIsValidateEmail] = useState(true);

    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [isAvaLoaded, setIsAvaLoaded] = useState(false);
    const [uploadedImgName, setUploadedImgName] = useState('');

    const [role, setRole] = useState('');
    const [alert, setAlert] = useState({
      txt: '',
      isVisible: false,
      type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
          ...errors,
          [event.target.name]: false
        });
    };

    const handleChangeEmail = (event) => {
        const emailRe = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
        setIsValidateEmail(emailRe.test(event.target.value));
        setValues({
            ...values,
            email: event.target.value
        });
    };

    const showAlert = (type, text) => {
      setAlert({
        txt: text,
        type,
        isVisible: true
      });

      setTimeout(() => {
        setAlert({
          txt: text,
          type,
          isVisible: false
        });

        setSubmitDisabled(false);
      }, 1400);
    };

    const avaUploaded = (event) => {
      let pathParts = event.target.value.split('\\');
      setUploadedImgName(pathParts[pathParts.length - 1]);
      setIsAvaLoaded(true);
      setValues({
        ...values,
        avatar: event.target.files[0]
      });
    }

    const validate = () => {
      let validComplete = true;
      let formErrors = {...errors};

      if (values.login.trim() === '') {
        validComplete = false;
        formErrors.login = true;
      }

      if (!isValidateEmail || values.email.trim() === '') {
        validComplete = false;
        formErrors.email = true;
        setIsValidateEmail(false);
      }

      setErrors(formErrors);
      return validComplete;
    };

    const submit = async () => {
      if (validate()) {
        setIsShowLoader(true);
        setSubmitDisabled(true);

        const payload = {
          login: values.login,
          email: values.email,
          role: role.toLowerCase(),
        };

        // if (values.email !== emailBeforeChange) {
        //   payload.email = values.email;
        // }

        // if (values.login !== '') {
        //   payload.login = values.login;
        // }

        if (values.name !== '') {
          payload.name = values.name;
        }

        if (values.surname !== '') {
          payload.sureName = values.surname;
        }

        if (values.avatar !== '') {
          payload.avatar = values.avatar;
        }

        let data = new FormData();
        for (let key in payload) {
          data.append(key, payload[key]);
        }

        let resp = await RequestService.put(`users/${id}`, {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        }, data, false);

        setIsShowLoader(false);

        if (resp.status === 'success') {
          showAlert('success', 'User edited');
        } else {

          if (resp.message === 'Пользователь с таким email уже существует') {
            showAlert('error', 'User with such email already exists');
          } else {
            showAlert('error', 'Error occured');
          }
        }

      }
    };

    useEffect(() => {
      RequestService.get(`users/${id}`, {
        Authorization: `Bearer ${TokenStorage.getAccessToken()}`
      })
      .then((resp) => {
        if (resp.status === 'success') {
          let userData = resp.data.user;
          let vals = {
            login: userData.login || '',
            email: userData.email,
            name: userData.name || '',
            surname: userData.sureName || '',
            avatar: '',
          };

          setValues(vals);
          setRole(firstLetterToUpperCase(userData.role));
          setEmailBeforeChange(userData.email);
        }
      });
    }, []);

    return (
      <form>
        <Card>
          <CardHeader
            title="Edit user"
          />
          <Divider />
          <CardContent sx={{ position: 'relative' }}>
              {isShowLoader && (
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#ffffff',
                  zIndex: 5
                  }}
                >
                    <CircularProgress />
                </Box>
              )}
              <Typography color="textPrimary" variant="h4">Avatar</Typography>
              <Box>
                <input accept=".png, .jpg, .jpeg, .webp" type="file" style={{ display: 'none' }} id="userAvatarUpload" onChange={avaUploaded} />
                <Button variant="contained" style={{ marginTop: '7px' }} component="span">
                  <label htmlFor="userAvatarUpload">
                    Upload
                  </label>
                </Button>
                <Typography
                  style={{
                    display: isAvaLoaded ? 'inline' : 'none',
                    marginLeft: '8px',
                    position: 'relative',
                    top: '3.5px'
                  }}
                >
                  {uploadedImgName}
                </Typography>
              </Box>
              <TextField
                fullWidth
                label="Login"
                margin="normal"
                name="login"
                onChange={handleChange}
                type="text"
                value={values.login}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                onChange={handleChangeEmail}
                type="email"
                value={values.email}
                variant="outlined"
                error={!isValidateEmail}
              />
              <TextField
                fullWidth
                label="Name"
                margin="normal"
                name="name"
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Surname"
                margin="normal"
                name="surname"
                onChange={handleChange}
                type="text"
                value={values.surname}
                variant="outlined"
              />
              <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                <InputLabel id="userRole">Role</InputLabel>
                <Select
                  labelId="userRole"
                  name="role"
                  value={role}
                  label="Role"
                  onChange={(evt) => setRole(evt.target.value)}
                >
                  <MenuItem value="Developer">Developer</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                </Select>
              </FormControl>
              <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                {alert.txt}
              </Alert>
          </CardContent>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={submit}
                disabled={submitDisabled}
              >
                  Редактировать
              </Button>
          </Box>
        </Card>
      </form>
    );
};

UserFormEdit.propTypes = {
  id: PropTypes.string.isRequired
};

export default UserFormEdit;
