import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TableFooter,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Divider,
  CardHeader,
} from "@material-ui/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import PlatformSkelet from "../../skeletons/PlatformSkelet";
import parseServerDate from "src/API/parseServerDate";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const ServerCheckLog = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [logs, setLogs] = useState([]);
  const [logsUrl, setLogsUrl] = useState("");
  const [logsTxt, setLogsTxt] = useState("");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const loadLogs = (setParams = true) => {
    setIsDataLoading(true);

    let endpoint = `checkServer/logs/${id}?page=${page + 1}&limit=${limit}`;

    RequestService.get(endpoint, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then(async (resp) => {
        if (resp.status === "success") {
          if (resp.data.logsUrl) {
            setLogsUrl(resp.data.logsUrl);

            await fetch(resp.data.logsUrl, {
              method: "GET",
            })
              .then((response) => response.text())
              .then((data) => {
                setLogsTxt(data);
              });
            // window.open(resp.data.logsUrl, "_blank");
            // return navigate(-1);
          }
          const logs = resp.data.logs;
          setIsDataLoaded(true);
          setLogs(logs);
          setCount(resp.data.total);
        } else {
          setIsDataLoaded(false);
          setErrMsg("Произошла ошибка: данные не загружены");
        }

        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
        setIsDataLoaded(false);
        setErrMsg("Произошла ошибка: данные не загружены");
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    loadLogs();
  }, [page, limit]);

  return (
    <>
      <Helmet>
        <title>Логи сервера</title>
      </Helmet>
      <Box sx={{ py: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Container>
      </Box>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          {isDataLoading ? (
            <PlatformSkelet />
          ) : (
            <>
              {isDataLoaded ? (
                <Box sx={{ pt: 3 }}>
                  <Card>
                    <PerfectScrollbar>
                      <CardHeader title="Логи сервера" />
                      <Divider />
                      {logsTxt ? (
                        <iframe
                          srcDoc={logsTxt}
                          frameborder="0"
                          border="0"
                          cellspacing="0"
                          style={{borderStyle: "none", width: "100%", height: "600px"}}
                        >
                          Ваш браузер не поддерживает встроенные фреймы!
                        </iframe>
                      ) : (
                        <Table>
                          <TableHead>
                            <TableRow>
                              {Object.keys(logs[0] || {}).map((key) => {
                                return <TableCell key={key}>{key}</TableCell>;
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {logs.map((log, ind) => (
                              <TableRow hover key={ind}>
                                {Object.keys(log).map((key) => (
                                  <TableCell
                                    key={key}
                                    style={{
                                      maxWidth: 250,
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {JSON.stringify(log[key])}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                colSpan={7}
                                count={count}
                                rowsPerPage={limit}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeLimit}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      )}
                    </PerfectScrollbar>
                  </Card>
                </Box>
              ) : (
                <Typography variant="h3" style={{ marginTop: "14px" }}>
                  {errMsg}
                </Typography>
              )}
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default ServerCheckLog;
