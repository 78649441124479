import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import PlatformSkelet from "../../skeletons/PlatformSkelet";

const PaymentsDeleted = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [deletedPayments, setDeletedPayments] = useState([]);

  const navigate = useNavigate();

  const loadDeletedProjects = () => {
    setIsDataLoading(true);

    RequestService.get("payments/deleted", {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          const projects = resp.data.payments;

          if (projects.length > 0) {
            setIsDataLoaded(true);
            setDeletedPayments(projects);
          } else {
            setIsDataLoaded(false);
            setErrMsg("У вас пока нет удалённых платежей");
          }
        } else {
          setIsDataLoaded(false);
          setErrMsg("Произошла ошибка: данные не загружены");
        }

        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
        setIsDataLoaded(false);
        setErrMsg("Произошла ошибка: данные не загружены");
      });
  };

  const onRestore = (id) => {
    RequestService.get(`payments/restore/${id}`, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    }).then((resp) => {
      if (resp.status === "success") {
        loadDeletedProjects();
      }
    });
  };

  useEffect(() => {
    loadDeletedProjects();
  }, []);

  return (
    <>
      <Helmet>
        <title>Удалённые платежи</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          <Box>
            <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
              Назад
            </Button>
          </Box>
          {isDataLoading ? (
            <PlatformSkelet />
          ) : isDataLoaded ? (
            <Box sx={{ pt: 2 }}>
              <Card>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Проект</TableCell>
                      <TableCell>Плательщик</TableCell>
                      <TableCell>Сумма</TableCell>
                      <TableCell>Дата</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deletedPayments.map((payment, ind) => (
                      <TableRow hover key={ind}>
                        <TableCell
                          style={{
                            width: "130px",
                            minWidth: "90px",
                            wordBreak: "break-all",
                          }}
                        >
                          {payment._id}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "170px",
                            wordBreak: "break-all",
                          }}
                        >
                          {payment.projectmName}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "170px",
                            wordBreak: "break-all",
                          }}
                        >
                          {payment.payer}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "130px",
                            wordBreak: "break-all",
                          }}
                        >
                          {payment.amount} {payment.currency}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "130px",
                            wordBreak: "break-all",
                          }}
                        >
                          {new Date(payment.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Box sx={{ ml: 1 }}>
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() => onRestore(payment._id)}
                              >
                                Восстановление
                              </Button>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </Box>
          ) : (
            <Typography variant="h3" style={{ marginTop: "14px" }}>
              {errMsg}
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PaymentsDeleted;
