import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button,
    TableFooter,
    TablePagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Divider
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useConfirm } from '../../components/confirm';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import PlatformSkelet from '../../skeletons/PlatformSkelet';

const TariffList = () => {
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [tariffs, setTariffs] = useState([]);

    const [filterId, setFilterId] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterPlatformId, setFilterPlatformId] = useState('');
    const [curSearchVal, setCurSearchVal] = useState('Id');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const confirm = useConfirm();

    const loadTariffs = (setParams = true) => {
        setIsDataLoading(true);

        let endpoint = `tariffs?page=${page + 1}&limit=${limit}`;

        if (setParams) {
            switch (curSearchVal) {
                case 'Id':
                    if (filterId !== '') {
                        endpoint = `tariffs?_id=${filterId}`;
                    }
                break;

                case 'Name':
                    if (filterName !== '') {
                        endpoint = `tariffs?name=${filterName}`;
                    }
                break;

                case 'Id платформы':
                    if (filterPlatformId !== '') {
                        endpoint = `tariffs?platform=${filterPlatformId}`;
                    }
                break;
            }
        }

        endpoint += "&sort=-createdAt"
        RequestService.get(endpoint, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                const tariffs = resp.data.tariffs;
                setIsDataLoaded(true);
                setTariffs(tariffs);
                setCount(resp.total);
            } else {
                setIsDataLoaded(false);
                setErrMsg('Произошла ошибка: данные не загружены');
            }

            setIsDataLoading(false);
        })
        .catch((err) => {
            setIsDataLoading(false);
            setIsDataLoaded(false);
            setErrMsg('Произошла ошибка: данные не загружены');
        });
    };

    const onDelete = (id) => {
        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить тариф?',
            onConfirm: () => {
                RequestService.delete(`tariffs/${id}`, {
                    Authorization: `Bearer ${TokenStorage.getAccessToken()}`
                })
                .then((resp) => {
                    if (resp.status === 'success') {
                        loadTariffs();
                    }
                });
            }
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterId = (event) => {
        setFilterId(event.target.value);

        if (event.target.value === '') {
            loadTariffs(false);
        }
    };

    const handleFilterName = (event) => {
        setFilterName(event.target.value);

        if (event.target.value === '') {
            loadTariffs(false);
        }
    };

    const handleFilterPlatformId = (event) => {
        setFilterPlatformId(event.target.value);

        if (event.target.value === '') {
            loadTariffs(false);
        }
    };

    const handleFilter = () => {
        loadTariffs();
    }

    const searchInputChange = (newVal) => {
        setCurSearchVal(newVal);

        setFilterName('');
        setFilterId('');
        setFilterPlatformId('');

        if (filterName !== '' || filterId !== '' || filterPlatformId !== '') {
            loadTariffs(false);
        }
    };

    useEffect(() => {
        loadTariffs();
    }, [page, limit]);

    return (
        <>
            <Helmet>
                <title>Тарифы</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <PlatformSkelet />
                            :
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box>
                                        <Link to="/app/tariffs/add">
                                            <Button color="primary" variant="contained">
                                                Добавить тариф
                                            </Button>
                                        </Link>
                                    </Box>

                                    <Box sx={{ ml: 1 }}>
                                        <Link to="/app/tariffs/deleted">
                                            <Button color="primary" variant="contained">
                                                Удаленные тарифы
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                {
                                    isDataLoaded ?
                                        <Box sx={{ pt: 3 }}>
                                            <Card>
                                                <PerfectScrollbar>
                                                    <Box sx={{ pt: 1, pb: 2, px: 2 }}>
                                                        <Box>
                                                            <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                                                <InputLabel id="searchType">По какому полю фильтровать список</InputLabel>
                                                                <Select
                                                                  labelId="searchType"
                                                                  name="searchValue"
                                                                  value={curSearchVal}
                                                                  label="По какому полю фильтровать список"
                                                                  onChange={(evt) => searchInputChange(evt.target.value)}
                                                                >
                                                                    <MenuItem value="Id">Id</MenuItem>
                                                                    <MenuItem value="Name">Название</MenuItem>
                                                                    <MenuItem value="Platform id">Id платформы</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>

                                                        <Box sx={{
                                                                    mt: 1,
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center'
                                                                }}
                                                        >
                                                            <TextField label="Id"
                                                                       name="filterId"
                                                                       onChange={handleFilterId}
                                                                       type="text"
                                                                       value={filterId}
                                                                       variant="outlined"
                                                                       fullWidth
                                                                       sx={{ mr: 2, display: curSearchVal === 'Id' ? 'block' : 'none' }}
                                                            />

                                                            <TextField label="Название"
                                                                    name="filterName"
                                                                    onChange={handleFilterName}
                                                                    type="text"
                                                                    value={filterName}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ mr: 2, display: curSearchVal === 'Name' ? 'block' : 'none' }}
                                                            />

                                                            <TextField label="Id платформы"
                                                                    name="filterPlatformId"
                                                                    onChange={handleFilterPlatformId}
                                                                    type="text"
                                                                    value={filterPlatformId}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ mr: 2, display: curSearchVal === 'Platform id' ? 'block' : 'none' }}
                                                            />

                                                            <Button
                                                              color="primary"
                                                              variant="contained"
                                                              size="large"
                                                              onClick={handleFilter}
                                                            >
                                                                Применить
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    <Divider />
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Id
                                                                </TableCell>
                                                                <TableCell>
                                                                    Название
                                                                </TableCell>
                                                                <TableCell>
                                                                    Описание
                                                                </TableCell>
                                                                <TableCell>
                                                                    Платформа
                                                                </TableCell>
                                                                <TableCell>
                                                                    Цена
                                                                </TableCell>
                                                                <TableCell>
                                                                    Срок действия
                                                                </TableCell>
                                                                <TableCell />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {tariffs.map((tariff, ind) => (
                                                                <TableRow hover key={ind}>
                                                                    <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                                        {tariff._id}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {tariff.name}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {tariff.description || '---'}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                                        {tariff.platformName}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {tariff.price}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {tariff.duration}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                            <Box>
                                                                                <Link to={`/app/tariffs/edit/${tariff._id}`}>
                                                                                    <Button
                                                                                    color="primary"
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    >
                                                                                        Ред.
                                                                                    </Button>
                                                                                </Link>
                                                                            </Box>
                                                                            <Box sx={{ ml: 1 }}>
                                                                                <Button
                                                                                color="primary"
                                                                                variant="contained"
                                                                                size="small"
                                                                                onClick={() => onDelete(tariff._id)}
                                                                                >
                                                                                    Удалить
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                        <TableFooter>
                                                            <TableRow>
                                                                <TablePagination
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                                />
                                                            </TableRow>
                                                        </TableFooter>
                                                    </Table>
                                                </PerfectScrollbar>
                                            </Card>
                                        </Box>
                                        :
                                        <Typography variant="h3" style={{ marginTop: '14px' }}>
                                            {errMsg}
                                        </Typography>
                                }
                            </>
                    }
                </Container>
            </Box>
        </>
    )
};

export default TariffList;
