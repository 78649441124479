import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
  CircularProgress,
  Container,
} from "@material-ui/core";
import Helmet from "react-helmet";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useNavigate, useParams } from "react-router-dom";

const PlatfromUpdateAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    version: "",
    text: "",
  });
  const [errors, setErrors] = useState({
    name: false,
  });
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [platformUpdates, setPlatformUpdates] = useState([]);
  const [platform, setPlatform] = useState({});

  const [alert, setAlert] = useState({
    txt: "",
    isVisible: false,
    type: "error",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: false,
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true,
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false,
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.version.trim() === "") {
      validComplete = false;
      formErrors.version = true;
    }

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    let vals = { ...values };

    for (let key in vals) {
      vals[key] = "";
    }

    setValues(vals);
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      const payload = {
        version: values.version,
        text: values.text,
        platform: platform._id
      };


      RequestService.post(
        "platforms/updates",
        {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        payload
      )
        .then((resp) => {
          if (resp.status === "success") {
            showAlert("success", "Обновление для платформы добавлено");
            clearForm();
          } else {
            showAlert("error", resp.message);
          }

          setIsShowLoader(false);
        })
        .catch((err) => {
          showAlert("error", "Server error");
          setIsShowLoader(false);
        });
    }
  };

  const loadPlatform = (setParams = true) => {
    setIsDataLoading(true);

    let endpoint = `platforms/${id}`;

    RequestService.get(endpoint, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          console.log(resp.data);
          const platform = resp.data.platform;
          setIsDataLoaded(true);
          setPlatform(platform);
        } else {
          setIsDataLoaded(false);
          setErrMsg("Произошла ошибка: данные не загружены");
        }

        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
        setIsDataLoaded(false);
        setErrMsg("Произошла ошибка: данные не загружены");
      });
  };

  useEffect(() => {
    loadPlatform();
  }, []);

  return (
    <>
      <Helmet>
        <title>Добавление обновления платформы</title>
      </Helmet>

      <Box sx={{ py: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Container>
      </Box>

      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          <form>
            <Card>
              <CardHeader
                title={`Добавить обновление для платформы ${platform.name || ""}`}
              />
              <Divider />
              <CardContent sx={{ position: "relative" }}>
                {isShowLoader && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#ffffff",
                      zIndex: 5,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <TextField
                  fullWidth
                  label="Версия"
                  margin="normal"
                  name="version"
                  onChange={handleChange}
                  type="text"
                  value={values.version}
                  variant="outlined"
                  error={errors.version}
                />
                <TextField
                  fullWidth
                  multiline
                  label="Описание"
                  margin="normal"
                  name="text"
                  onChange={handleChange}
                  type="text"
                  value={values.text}
                  rows={8}
                  variant="outlined"
                />
                <Alert
                  severity={alert.type}
                  style={{ display: alert.isVisible ? "flex" : "none" }}
                >
                  {alert.txt}
                </Alert>
              </CardContent>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submit}
                  disabled={submitDisabled}
                >
                  Добавить
                </Button>
              </Box>
            </Card>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default PlatfromUpdateAdd;
