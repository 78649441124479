import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Divider,
  Alert,
  CardHeader,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { makeStyles } from "@material-ui/styles";
import {
  Link as RouterLink,
  useParams,
  useNavigate,
  Link,
} from "react-router-dom";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import parseServerDate from "../../API/parseServerDate";
import PlatformSkelet from "../../skeletons/PlatformSkelet";
import { useConfirm } from "src/components/confirm";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles({
  wrap: {
    flexDirection: "row",
    display: "flex",
    padding: 10,
  },
  wrapAvatar: {
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
  },
  wrapInner: {
    flexDirection: "row",
    display: "flex",
  },
  label: {
    width: 200,
    fontSize: 18,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 18,
    fontFamily: "Roboto",
    fontWeight: "bold",
    padding: 10,
  },
  text: {
    fontSize: 18,
    fontFamily: "Roboto",
  },
  panel: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: "#5664d21c",
  },
  panelInCareington: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: "#79b3ff1c",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
});

const Project = () => {
  const { id } = useParams();

  const [project, setProject] = useState({});
  const [projectNotifications, setProjectNotifications] = useState([]);
  const [requestError, setRequestError] = useState(false);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const styles = useStyles();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const loadProjectNotifications = () => {
    RequestService.get(
      `projects/notifications?project=${id}&page=${
        page + 1
      }&limit=${limit}&sort=-createdAt&isCompleted=false`,
      {
        Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
      }
    )
      .then((resp) => {
        if (resp.status === "success") {
          setProjectNotifications(resp.data.projectNotifications);
          setCount(resp.total);
          console.log(resp.data.projectNotifications);
        } else {
          setRequestError(true);
        }
      })
      .catch((err) => {
        setRequestError(true);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const onDelete = (id) => {
    confirm({
      title: "Удаление",
      content: "Вы уверены, что хотите удалить уведомление?",
      onConfirm: () => {
        RequestService.delete(`projects/notifications/${id}`, {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
        }).then((resp) => {
          if (resp.status === "success") {
            loadProjectNotifications();
          }
        });
      },
    });
  };

  const onComplete = (id) => {
    RequestService.post(`projects/notifications/completed/${id}`, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    }).then((resp) => {
      if (resp.status === "success") {
        loadProjectNotifications();
      }
    });
  };

  useEffect(() => {
    RequestService.get(`projects/${id}`, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        console.log(resp);
        if (resp.status === "success") {
          setProject(resp.data.project);
          console.log(resp.data.project);
        } else {
          setRequestError(true);
        }
      })
      .catch((err) => {
        setRequestError(true);
      });
  }, []);

  useEffect(() => {
    loadProjectNotifications();
  }, [page, limit]);

  return (
    <>
      <Helmet>
        <title>Проект</title>
      </Helmet>
      <Box sx={{ py: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "background.default", mb: 5 }}>
        <Container maxWidth={false}>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <Alert
                severity="error"
                style={{
                  display: requestError ? "flex" : "none",
                  marginBottom: "16px",
                }}
              >
                Ошибка загрузки данных
              </Alert>
              <div className={styles.wrapAvatar}>
                <div>
                  <div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Id:</div>
                      <div className={styles.text}>{project._id || "---"}</div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Название:</div>
                      <div className={styles.text}>{project.name || "---"}</div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Тариф:</div>
                      <div className={styles.text}>
                        {project.tariffrmName || "---"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Платформа:</div>
                      <div className={styles.text}>
                        {project.platformName || "---"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Публичный ключ:</div>
                      <div className={styles.text}>
                        {project.publicKey || "---"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Email:</div>
                      <div className={styles.text}>
                        {project.email || "---"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Описание:</div>
                      <div className={styles.text}>
                        {project.description || "---"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Идентификатор для кабинета клиентов:</div>
                      <div className={styles.text}>
                        {project.clientId || "---"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Активен до:</div>
                      <div className={styles.text}>
                        {project.activeUntil
                          ? parseServerDate(project.activeUntil)
                          : "---"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Последний платеж:</div>
                      <div className={styles.text}>
                        {project.lastPayment
                          ? parseServerDate(project.lastPayment)
                          : "---"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Последнее обновление:</div>
                      <div className={styles.text}>
                        {project.updatedAt
                          ? parseServerDate(project.updatedAt)
                          : "---"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Статус:</div>
                      <div className={styles.text}>
                        {project.status || "---"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Активен:</div>
                      <div className={styles.text}>
                        {project.isActive ? "Да" : "Нет"}
                      </div>
                    </div>
                    <div className={styles.wrap}>
                      <div className={styles.label}>Внутренний проект:</div>
                      <div className={styles.text}>
                        {project.autoRenewal ? "Да" : "Нет"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
              <RouterLink to={`/app/projects/edit/${id}`}>
                <Button color="primary" variant="contained">
                  Редактировать
                </Button>
              </RouterLink>
            </Box>
          </Card>
        </Container>
      </Box>

      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          {false ? (
            <PlatformSkelet />
          ) : (
            <>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box>
                  <Link to={`/app/projects/notifications/add/${id}`}>
                    <Button color="primary" variant="contained">
                      Добавить уведомление
                    </Button>
                  </Link>
                </Box>

                <Box sx={{ ml: 1 }}>
                  <Link to={`/app/projects/notifications/deleted/${id}`}>
                    <Button color="primary" variant="contained">
                      Удаленные уведомления
                    </Button>
                  </Link>
                </Box>

                <Box sx={{ ml: 1 }}>
                  <Link to={`/app/projects/notifications/completed/${id}`}>
                    <Button color="primary" variant="contained">
                      Завершенные уведомления
                    </Button>
                  </Link>
                </Box>
              </Box>

              <Box sx={{ pt: 3 }}>
                <Card>
                  <CardHeader title="Уведомления" />
                  <Divider />
                  <PerfectScrollbar>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Заголовок</TableCell>
                          <TableCell>Описание</TableCell>
                          <TableCell>Стоимость</TableCell>
                          <TableCell>Дата оплаты</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectNotifications.map((notification, ind) => (
                          <TableRow hover key={notification._id}>
                            <TableCell
                              style={{
                                width: "130px",
                                minWidth: "90px",
                                wordBreak: "break-all",
                              }}
                            >
                              {notification._id}
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "170px",
                                wordBreak: "break-all",
                              }}
                            >
                              {notification.title}
                            </TableCell>
                            <TableCell
                              style={{
                                width: "130px",
                                minWidth: "90px",
                                wordBreak: "break-all",
                              }}
                            >
                              {notification.description}
                            </TableCell>
                            <TableCell
                              style={{
                                width: "130px",
                                minWidth: "90px",
                                wordBreak: "break-all",
                              }}
                            >
                              {notification.cost}
                            </TableCell>
                            <TableCell
                              style={{
                                width: "190px",
                                minWidth: "90px",
                                wordBreak: "break-all",
                              }}
                            >
                              {new Date(
                                notification.paymentDate
                              ).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Box>
                                  <Link
                                    to={`/app/projects/notifications/edit/${notification._id}`}
                                  >
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="small"
                                    >
                                      Ред.
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ ml: 1 }}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={() => onComplete(notification._id)}
                                  >
                                    Завершить
                                  </Button>
                                </Box>
                                <Box sx={{ ml: 1 }}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={() => onDelete(notification._id)}
                                  >
                                    Удалить
                                  </Button>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            colSpan={7}
                            count={count}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeLimit}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Project;
