import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import User from './pages/User/User';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import UserAdd from './pages/User/UserAdd';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import PlatformList from './pages/Platform/PlatformList';
import PlatformAdd from './pages/Platform/PlatfromAdd';
import PlatformEdit from './pages/Platform/PlatformEdit';
import PlatformsDeleted from './pages/Platform/PlatformsDeleted';
import PlatformTariffs from './pages/Platform/PlatformTariffs';
import TariffList from './pages/Tariff/TariffList';
import TariffAdd from './pages/Tariff/TariffAdd';
import TariffEdit from './pages/Tariff/TariffEdit';
import TariffsDeleted from './pages/Tariff/TariffsDeleted';
import ProjectList from './pages/Project/ProjectList';
import ProjectAdd from './pages/Project/ProjectAdd';
import ProjectEdit from './pages/Project/ProjectEdit';
import Project from './pages/Project/Project';
import ProjectsDeleted from './pages/Project/ProjectsDeleted';
import ProjectNotificationAdd from './pages/ProjectNotification/ProjectNotificationAdd';
import ProjectNotificationEdit from './pages/ProjectNotification/ProjectNotificationEdit';
import ProjectNotificationsDeleted from './pages/ProjectNotification/ProjectNotificationsDeleted';
import ProjectNotificationsCompleted from './pages/ProjectNotification/ProjectNotificationsCompleted';
import PaymentList from './pages/Payment/PaymentList';
import PaymentAdd from './pages/Payment/PaymentAdd';
import PaymentEdit from './pages/Payment/PaymentEdit';
import LogList from './pages/Log/LogList';
import Log from './pages/Log/Log';
import ServerCheckList from "./pages/ServerCheck/ServerCheckList";
import ServerCheckEdit from "./pages/ServerCheck/ServerCheckEdit";
import ServerCheckAdd from "./pages/ServerCheck/ServerCheckAdd";
import ServerCheckLog from "./pages/ServerCheck/ServerCheckLog";
import ServerCheckDeleted from "./pages/ServerCheck/ServerCheckDeleted";
import ServerCheckInfo from "./pages/ServerCheck/ServerCheckInfo";
import PlatformUpdateList from './pages/PlatformUpdate/PlatformUpdateList';
import PlatformUpdateAdd from './pages/PlatformUpdate/PlatformUpdateAdd';
import PlatformUpdatesDeleted from "./pages/PlatformUpdate/PlatformUpdatesDeleted";
import PlatformUpdateEdit from "./pages/PlatformUpdate/PlatformUpdateEdit";
import PaymentsDeleted from './pages/Payment/PaymentsDeleted';
import SecretKeyEdit from './pages/SecretKey/SecretKeyEdit';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'user/:id', element: <User /> },
      { path: 'users/add', element: <UserAdd /> },
      { path: 'user/edit/:id', element: <UserEdit /> },
      { path: 'users', element: <UserList /> },
      { path: 'dashboard', element: <Dashboard /> },

      { path: 'server-check', element: <ServerCheckList /> },
      { path: 'server-check/add', element: <ServerCheckAdd /> },
      { path: 'server-check/log/:id', element: <ServerCheckLog /> },
      { path: 'server-check/info/:id', element: <ServerCheckInfo /> },
      { path: 'server-check/edit/:id', element: <ServerCheckEdit /> },
      { path: 'server-check/deleted', element: <ServerCheckDeleted /> },

      { path: 'platforms/updates/:id', element: <PlatformUpdateList /> },
      { path: 'platforms/updates/add/:id', element: <PlatformUpdateAdd /> },
      { path: 'platforms/updates/deleted/:id', element: <PlatformUpdatesDeleted /> },
      { path: 'platforms/updates/edit/:updateId', element: <PlatformUpdateEdit /> },


      { path: 'platforms', element: <PlatformList /> },
      { path: 'platforms/add', element: <PlatformAdd /> },
      { path: 'platforms/edit/:id', element: <PlatformEdit /> },
      { path: 'platforms/deleted', element: <PlatformsDeleted /> },
      { path: 'platforms/tarrifs/:id', element: <PlatformTariffs /> },

      { path: 'tariffs', element: <TariffList /> },
      { path: 'tariffs/add', element: <TariffAdd /> },
      { path: 'tariffs/edit/:id', element: <TariffEdit /> },
      { path: 'tariffs/deleted', element: <TariffsDeleted /> },

      { path: 'projects/notifications/add/:id', element: <ProjectNotificationAdd /> },
      { path: 'projects/notifications/edit/:notificationId', element: <ProjectNotificationEdit /> },
      { path: 'projects/notifications/deleted/:id', element: <ProjectNotificationsDeleted /> },
      { path: 'projects/notifications/completed/:id', element: <ProjectNotificationsCompleted /> },

      { path: 'projects', element: <ProjectList /> },
      { path: 'projects/add', element: <ProjectAdd /> },
      { path: 'projects/edit/:id', element: <ProjectEdit /> },
      { path: 'project/:id', element: <Project /> },
      { path: 'projects/deleted', element: <ProjectsDeleted /> },

      { path: 'payments', element: <PaymentList /> },
      { path: 'payments/add', element: <PaymentAdd /> },
      { path: 'payments/edit/:id', element: <PaymentEdit /> },
      { path: 'payments/deleted', element: <PaymentsDeleted /> },
      { path: 'logs', element: <LogList /> },
      { path: 'log/:id', element: <Log /> },

      { path: 'secret', element: <SecretKeyEdit /> },

      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
