import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button,
    TableFooter,
    TablePagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Divider
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import PlatformSkelet from '../../skeletons/PlatformSkelet';
import parseServerDate from 'src/API/parseServerDate';

const LogList = () => {
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [logs, setLogs] = useState([]);

    const [filterVals, setFilterVals] = useState({
        id: '',
        targetName: '',
        targetId: '',
        executor: ''
    });

    const [filterOperation, setFilterOperation] = useState('Без фильтра');
    const [curSearchVal, setCurSearchVal] = useState('Id');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const changeValue = (evt) => {
        setFilterVals({
            ...filterVals,
            [evt.target.name]: evt.target.value
        });

        if (evt.target.value === '') {
            loadLogs(false);
        }
    };

    const loadLogs = (setParams = true) => {
        setIsDataLoading(true);

        let endpoint = `logs?page=${page + 1}&limit=${limit}`;

        if (setParams) {
            switch (curSearchVal) {
                case 'Id':
                    if (filterVals.id !== '') {
                        endpoint = `logs?_id=${filterVals.id}`;
                    }
                break;

                case 'Target name':
                    if (filterVals.targetName !== '') {
                        endpoint = `logs?targetName=${filterVals.targetName}`;
                    }
                break;

                case 'Target id':
                    if (filterVals.targetId !== '') {
                        endpoint = `logs?targetId=${filterVals.targetId}`;
                    }
                break;

                case 'Executor':
                    if (filterVals.executor !== '') {
                        endpoint = `logs?executor=${filterVals.executor}`;
                    }
                break;

                case 'Operation type':
                    if (filterOperation !== 'Без фильтра') {
                        endpoint = `logs?type=${filterOperation}`;
                    }
                break;
            }
        }

        endpoint += "&sort=-createdAt"
        RequestService.get(endpoint, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                const logs = resp.data.logs;
                setIsDataLoaded(true);
                setLogs(logs);
                setCount(resp.total);
            } else {
                setIsDataLoaded(false);
                setErrMsg('Произошла ошибка: данные не загружены');
            }

            setIsDataLoading(false);
        })
        .catch((err) => {
            setIsDataLoading(false);
            setIsDataLoaded(false);
            setErrMsg('Произошла ошибка: данные не загружены');
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilter = () => {
        loadLogs();
    }

    const searchInputChange = (newVal) => {
        setCurSearchVal(newVal);

        setFilterVals({
            id: '',
            targetName: '',
            targetId: '',
            executor: ''
        });

        setFilterOperation('Без фильтра');

        let isAnyFilter = false;

        Object.values(filterVals).forEach((value) => {
            if (value !== '') {
                isAnyFilter = true;
            }
        });

        if (isAnyFilter || filterOperation !== 'Без фильтра') {
            loadLogs(false);
        }
    };

    useEffect(() => {
        loadLogs();
    }, [page, limit]);

    return (
        <>
            <Helmet>
                <title>Логи</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <PlatformSkelet />
                            :
                            <>
                                {
                                    isDataLoaded ?
                                        <Box sx={{ pt: 3 }}>
                                            <Card>
                                                <PerfectScrollbar>
                                                    <Box sx={{ pt: 1, pb: 2, px: 2 }}>
                                                        <Box>
                                                            <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                                                <InputLabel id="searchType">По какому полю фильтровать список</InputLabel>
                                                                <Select
                                                                  labelId="searchType"
                                                                  name="searchValue"
                                                                  value={curSearchVal}
                                                                  label="По какому полю фильтровать список"
                                                                  onChange={(evt) => searchInputChange(evt.target.value)}
                                                                >
                                                                    <MenuItem value="Id">Id</MenuItem>
                                                                    <MenuItem value="Target name">Источник</MenuItem>
                                                                    <MenuItem value="Target id">Id источника</MenuItem>
                                                                    <MenuItem value="Executor">Исполнитель</MenuItem>
                                                                    <MenuItem value="Operation type">Тип операции</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>

                                                        <Box sx={{
                                                                    mt: 1,
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center'
                                                                }}
                                                        >
                                                            <TextField label="Id"
                                                                       name="id"
                                                                       onChange={changeValue}
                                                                       type="text"
                                                                       value={filterVals.id}
                                                                       variant="outlined"
                                                                       fullWidth
                                                                       sx={{ mr: 2, display: curSearchVal === 'Id' ? 'block' : 'none' }}
                                                            />

                                                            <TextField label="Источник"
                                                                    name="targetName"
                                                                    onChange={changeValue}
                                                                    type="text"
                                                                    value={filterVals.targetName}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ mr: 2, display: curSearchVal === 'Target name' ? 'block' : 'none' }}
                                                            />

                                                            <TextField label="Id источника"
                                                                    name="tariffId"
                                                                    onChange={changeValue}
                                                                    type="text"
                                                                    value={filterVals.tariffId}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ mr: 2, display: curSearchVal === 'Target id' ? 'block' : 'none' }}
                                                            />

                                                            <TextField label="Исполнитель"
                                                                    name="executor"
                                                                    onChange={changeValue}
                                                                    type="text"
                                                                    value={filterVals.executor}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ mr: 2, display: curSearchVal === 'Executor' ? 'block' : 'none' }}
                                                            />

                                                            <FormControl fullWidth sx={{ mr: 2, display: curSearchVal === 'Operation type' ? 'block' : 'none' }}>
                                                                <InputLabel id="operType">Тип операции</InputLabel>
                                                                <Select
                                                                  labelId="operType"
                                                                  name="operType"
                                                                  value={filterOperation}
                                                                  fullWidth
                                                                  label="Тип операции"
                                                                  onChange={(evt) => setFilterOperation(evt.target.value)}
                                                                >
                                                                    <MenuItem value="Без фильтра">Без фильтра</MenuItem>
                                                                    <MenuItem value="create">create</MenuItem>
                                                                    <MenuItem value="delete">delete</MenuItem>
                                                                    <MenuItem value="restore">restore</MenuItem>
                                                                    <MenuItem value="update">update</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                            <Button
                                                              color="primary"
                                                              variant="contained"
                                                              size="large"
                                                              onClick={handleFilter}
                                                            >
                                                                Применить
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    <Divider />
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Id
                                                                </TableCell>
                                                                <TableCell>
                                                                    Тип операции
                                                                </TableCell>
                                                                <TableCell>
                                                                    Источник
                                                                </TableCell>
                                                                <TableCell>
                                                                    Id источника
                                                                </TableCell>
                                                                <TableCell>
                                                                    Исполнитель
                                                                </TableCell>
                                                                <TableCell>
                                                                    Время
                                                                </TableCell>
                                                                <TableCell>
                                                                    Авто
                                                                </TableCell>
                                                                <TableCell />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {logs.map((log, ind) => (
                                                                <TableRow hover key={ind}>
                                                                    <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                                        {log._id}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {log.type}
                                                                    </TableCell>
                                                                    <TableCell style={{ maxWidth: '170px', wordBreak: 'break-all' }}>
                                                                        {log.targetName}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                                        {log.targetId}
                                                                    </TableCell>
                                                                    <TableCell style={{ maxWidth: '170px', wordBreak: 'break-all' }}>
                                                                        {log.executor}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {parseServerDate(log.createdAt)}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {log.isAutomatic ? 'Да' : 'Нет'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                            <Box>
                                                                                <Link to={`/app/log/${log._id}`}>
                                                                                    <Button
                                                                                      color="primary"
                                                                                      variant="contained"
                                                                                      size="small"
                                                                                    >
                                                                                        Инфо.
                                                                                    </Button>
                                                                                </Link>
                                                                            </Box>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                        <TableFooter>
                                                            <TableRow>
                                                                <TablePagination
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                                />
                                                            </TableRow>
                                                        </TableFooter>
                                                    </Table>
                                                </PerfectScrollbar>
                                            </Card>
                                        </Box>
                                        :
                                        <Typography variant="h3" style={{ marginTop: '14px' }}>
                                            {errMsg}
                                        </Typography>
                                }
                            </>
                    }
                </Container>
            </Box>
        </>
    )
};

export default LogList;
