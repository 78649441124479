import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Budget from "../components/dashboard/Budget";
import LatestOrders from "../components/dashboard/LatestOrders";
import LatestProducts from "../components/dashboard/LatestProducts";
import Sales from "../components/dashboard/Sales";
import TasksProgress from "../components/dashboard/TasksProgress";
import TotalCustomers from "../components/dashboard/TotalCustomers";
import TotalProfit from "../components/dashboard/TotalProfit";
import TrafficByDevice from "../components/dashboard/TrafficByDevice";
import { useEffect, useState } from "react";
import TokenStorage from "src/API/TokenStorage";
import RequestService from "src/API/RequestService";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [expiredProjectNotifications, setExpiredProjectNotifications] =
    useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const loadExpiredProjectNotifications = () => {
    setIsDataLoading(true);

    RequestService.get(`projects/notifications/expired?page=${page+1}&limit=${limit}`, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          const projectNotifications = resp.data.projectNotifications;

          if (projectNotifications.length > 0) {
            setIsDataLoaded(true);
            setExpiredProjectNotifications(projectNotifications);
            setCount(resp.data.total);
          } else {
            setIsDataLoaded(false);
            setErrMsg(
              "У вас пока нет завершенных уведомлений для этого проекта"
            );
          }
        } else {
          setIsDataLoaded(false);
          setErrMsg("Произошла ошибка: данные не загружены");
        }

        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
        setIsDataLoaded(false);
        setErrMsg("Произошла ошибка: данные не загружены");
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    loadExpiredProjectNotifications();
  }, [page, limit]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Budget />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalCustomers />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TasksProgress />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit sx={{ height: "100%" }} />
            </Grid>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <Sales />
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <TrafficByDevice sx={{ height: "100%" }} />
            </Grid>
            <Grid item lg={0} md={12}>
              <Box sx={{width: "100%"}}>
                <Card>
                  <CardHeader title="Уведомления" />
                  <Divider />
                  <PerfectScrollbar>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Заголовок</TableCell>
                          <TableCell>Описание</TableCell>
                          <TableCell>Стоимость</TableCell>
                          <TableCell>Дата оплаты</TableCell>
                          <TableCell>Проект</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expiredProjectNotifications.map((notification, ind) => (
                          <TableRow hover key={notification._id}>
                            <TableCell
                              style={{
                                width: "130px",
                                minWidth: "90px",
                                wordBreak: "break-all",
                              }}
                            >
                              {notification._id}
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "170px",
                                wordBreak: "break-all",
                              }}
                            >
                              {notification.title}
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "200px",
                                wordBreak: "break-all",
                              }}
                            >
                              {notification.description}
                            </TableCell>
                            <TableCell
                              style={{
                                maxWidth: "130px",
                                wordBreak: "break-all",
                              }}
                            >
                              {notification.cost}
                            </TableCell>
                            <TableCell
                              style={{
                                width: "190px",
                                minWidth: "90px",
                                wordBreak: "break-all",
                              }}
                            >
                              {new Date(
                                notification.paymentDate
                              ).toLocaleDateString()}
                            </TableCell>
                            <TableCell
                              style={{
                                width: "190px",
                                minWidth: "90px",
                                wordBreak: "break-all",
                              }}
                            >
                              {notification.project.name}
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Box>
                                  <Link
                                    to={`/app/project/${notification.project._id}`}
                                  >
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="small"
                                    >
                                      К проекту
                                    </Button>
                                  </Link>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            colSpan={7}
                            count={count}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeLimit}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <LatestProducts sx={{ height: "100%" }} />
            </Grid>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <LatestOrders />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
