import React, {useState, useEffect} from 'react';
import {
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    CircularProgress,
    Alert,
    Container, InputLabel, Select, MenuItem, FormControl
} from '@material-ui/core';
import {useNavigate, useParams} from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Helmet from 'react-helmet';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';

const TariffEdit = (props) => {
    const {id} = useParams();
    const [values, setValues] = useState({
        name: '',
        price: '',
        duration: '',
        description: '',
        platform: '',
        platformName: '' 
    });
    const [errors, setErrors] = useState({
        name: false,
        price: false,
        duration: false
    });

    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const navigate = useNavigate();

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text, hideThen = true) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        if (hideThen) {
            setTimeout(() => {
                setAlert({
                    txt: text,
                    type,
                    isVisible: false
                });

                setSubmitDisabled(false);
            }, 1400);
        }
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        const isNumb = (inp) => {
            let res = true;

            for (let i = 0; i < inp.length; i++) {
                if (!(inp[i] >= '0' && inp[i] <= '9')) {
                    res = false;
                    break;
                }
            }

            return res;
        };

        if (values.name.trim() === '' || isNumb(values.name.trim())) {
            validComplete = false;
            formErrors.name = true;
        }

        if (!isNumb(values.price.trim())) {
            validComplete = false;
            formErrors.price = true;
        }

        if (!values.duration) {
            validComplete = false;
            formErrors.duration = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (validate()) {
            setIsShowLoader(true);
            setSubmitDisabled(true);

            const payload = {
                name: values.name,
                price: values.price,
                duration: values.duration.split("")[0]
            };

            if (values.description.trim() !== '') {
                payload.description = values.description;
            }

            RequestService.put(`tariffs/${id}`, {
                Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }, payload)
                .then((resp) => {
                    setIsShowLoader(false);

                    if (resp.status === 'success') {
                        showAlert('success', 'Тариф отредактирован');
                    } else {
                        showAlert('error', resp.message);
                    }
                })
                .catch((err) => {
                    setIsShowLoader(false);
                    showAlert('error', 'Произошла ошибка на сервере');
                });
        }
    };

    useEffect(() => {

        RequestService.get(`tariffs/${id}`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
            .then((resp) => {
                if (resp.status === 'success') {
                    let tariffData = resp.data.tariff;

                    let vals = {
                        name: tariffData.name || '',
                        price: `${tariffData.price}` || '',
                        duration: `${tariffData.duration} мес` || '',
                        description: tariffData.description || '',
                        platform: tariffData.platform || '',
                        platformName: tariffData.platformName || ''
                    };

                    setValues(vals);
                } else {
                    showAlert('error', 'Ошибка, данные не загружены', false);
                }
            })
            .catch((err) => {
                showAlert('error', 'Ошибка сервера, данные не загружены', false);
            });

    }, []);

    return (
        <>
            <Helmet>
                <title>Редактирование тарифа</title>
            </Helmet>
            <Box sx={{py: 2}}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                </Container>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <form>
                        <Card>
                            <CardHeader
                                title="Редактировать тариф"
                            />
                            <Divider/>
                            <CardContent sx={{position: 'relative'}}>
                                {isShowLoader && (
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#ffffff',
                                        zIndex: 5
                                    }}
                                    >
                                        <CircularProgress/>
                                    </Box>
                                )}
                                <TextField
                                    fullWidth
                                    label="Название"
                                    margin="normal"
                                    name="name"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.name}
                                    variant="outlined"
                                    error={errors.name}
                                />
                                <TextField
                                    fullWidth
                                    label="Цена (руб)"
                                    margin="normal"
                                    name="price"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.price}
                                    variant="outlined"
                                    error={errors.price}
                                    helperText={errors.price && 'Введите число'}
                                />
                                <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                    <InputLabel id="duration">Срок действия (мес)</InputLabel>
                                    <Select
                                        labelId="duration"
                                        name="duration"
                                        value={values.duration}
                                        label="Срок действия (мес)"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"1 мес"}>
                                            1 мес
                                        </MenuItem>
                                        <MenuItem value={"3 мес"}>
                                            3 мес
                                        </MenuItem>
                                        <MenuItem value={"12 мес"}>
                                            12 мес
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Описание"
                                    margin="normal"
                                    name="description"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.description}
                                    variant="outlined"
                                />
                                <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                            <InputLabel id="platform">Платформа</InputLabel>
                                            <Select
                                                labelId="platform"
                                                name="platform"
                                                label="Платформа"
                                                value={values.platform}
                                                disabled={true}
                                            >
                                                        <MenuItem key={values.platform} value={values.platform}>
                                                            {values.platformName} / {values.platform}
                                                        </MenuItem>
                                            </Select>
                                        </FormControl>
                                <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                    {alert.txt}
                                </Alert>
                            </CardContent>
                            <Divider/>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={submit}
                                    disabled={submitDisabled}
                                >
                                    Редактировать
                                </Button>
                            </Box>
                        </Card>
                    </form>
                </Container>
            </Box>
        </>
    );
};

export default TariffEdit;
