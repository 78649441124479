import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    CircularProgress,
    Container, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Helmet from 'react-helmet';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {useNavigate} from "react-router-dom";
import '../../styles/Server/serverStyles.css';

const ServerCheckAdd = () => {

    const navigate = useNavigate();

    const [projectsData, setProjectsData] = useState([]);
    const [chosenProjectId, setChosenProjectId] = useState('');

    const [values, setValues] = useState({
        project: '',
        emailsNotification: '',
        address: '',
        description: '',
        interval: '',
        name: '',
        logsUrl: ''
    });
    const [errors, setErrors] = useState({
        project: false,
        emailsNotification: false,
        address: false,
        description: false,
        interval: false,
        name: false,
    });
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name.trim() === '') {
            validComplete = false;
            formErrors.name = true;
        }

        setErrors(formErrors);
        return validComplete;
    };


    const clearForm = () => {
        let vals = {...values};

        for (let key in vals) {
            vals[key] = '';
        }

        setValues(vals);
    };


    const submit = async () => {
        if (validate()) {
            setIsShowLoader(true);
            setSubmitDisabled(true);

            let adressToSend = values.address.split(/\s*,\s*/);
            let emailsToSend = values.emailsNotification.split(/\s*,\s*/);

            const payload = {
                name: values.name,
                project: chosenProjectId,
                emailsNotification: emailsToSend,
                address: adressToSend,
                description: values.description,
                interval: values.interval,
                logsUrl: values.logsUrl
            };

            RequestService.post('checkServer', {
                Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
                'Content-Type': 'application/json'
            }, JSON.stringify(payload), false)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Платформа добавлена');
                        clearForm();
                    } else {
                        console.log(resp)
                        showAlert('error', resp.message);
                    }

                    setIsShowLoader(false);
                })
                .catch((err) => {
                    showAlert('error', 'Server error');
                    setIsShowLoader(false);
                });
        }
    };

    useEffect(() => {

        RequestService.get('projects?limit=0&sort=-createdAt', {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
            .then((resp) => {
                if (resp.status === 'success') {
                    const data = resp.data.projects;
                    setProjectsData(data);
                } else {
                }
            })
            .catch((err) => {

            });

    }, [])


    return (
        <>
            <Helmet>
                <title>Добавление платформы</title>
            </Helmet>

            <Box sx={{py: 2}}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <form>
                        <Card>
                            <CardHeader
                                title="Добавить сервер"
                            />
                            <Divider/>
                            <CardContent sx={{position: 'relative'}}>
                                {isShowLoader && (
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#ffffff',
                                        zIndex: 5
                                    }}
                                    >
                                        <CircularProgress/>
                                    </Box>
                                )}
                                <TextField
                                    fullWidth
                                    label="Название"
                                    margin="normal"
                                    name="name"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.name}
                                    variant="outlined"
                                    error={errors.name}
                                />
                                <TextField
                                    fullWidth
                                    label="Описание"
                                    margin="normal"
                                    name="description"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.description}
                                    variant="outlined"
                                />
                                <TextField
                                    fullWidth
                                    label="Электронные почты"
                                    margin="normal"
                                    name="emailsNotification"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.emailsNotification}
                                    variant="outlined"
                                />
                                <div className="helper">
                                    Введите значения через запятую
                                </div>
                                <TextField
                                    fullWidth
                                    label="Адреса"
                                    margin="normal"
                                    name="address"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.address}
                                    variant="outlined"
                                />
                                <div className="helper">
                                    Введите значения через запятую
                                </div>
                                <TextField
                                    fullWidth
                                    label="Адрес для получения лога"
                                    margin="normal"
                                    name="logsUrl"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.logsUrl}
                                    variant="outlined"
                                />
                                <TextField
                                    fullWidth
                                    label="Интервал"
                                    margin="normal"
                                    name="interval"
                                    onChange={handleChange}
                                    type="number"
                                    value={values.interval}
                                    variant="outlined"
                                />
                                <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                    <InputLabel id="tariff">Проект</InputLabel>
                                    <Select
                                        labelId="Project"
                                        name="project"
                                        value={chosenProjectId}
                                        label="Проект"
                                        onChange={(evt) => setChosenProjectId(evt.target.value)}
                                    >
                                        {
                                            projectsData.map((item, ind) => {
                                                return <MenuItem value={item._id} key={ind}>
                                                    {item.name}
                                                </MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                    {alert.txt}
                                </Alert>
                            </CardContent>
                            <Divider/>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={submit}
                                    disabled={submitDisabled}
                                >
                                    Добавить
                                </Button>
                            </Box>
                        </Card>
                    </form>
                </Container>
            </Box>
        </>
    );
};

export default ServerCheckAdd;
