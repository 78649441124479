import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
  CircularProgress,
  Container,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Helmet from "react-helmet";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import { useNavigate, useParams } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const ProjectNotificationAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    title: "",
    description: "",
    cost: 0,
    paymentDate: new Date(),
  });
  const [errors, setErrors] = useState({
    title: false,
    description: false,
    cost: false,
    paymentDate: false,
  });

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isValidateEmail, setIsValidateEmail] = useState(true);

  const [alert, setAlert] = useState({
    txt: "",
    isVisible: false,
    type: "error",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: false,
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true,
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false,
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.title.trim() === "") {
      validComplete = false;
      formErrors.title = true;
    }

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    let vals = { ...values };

    for (let key in vals) {
      vals[key] = "";
    }

    setValues(vals);
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      const payload = {
        title: values.title,
        description: values.description,
        cost: values.cost,
        paymentDate: new Date(values.paymentDate).toJSON(),
        project: id,
      };

      RequestService.post(
        "projects/notifications/",
        {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        payload
      )
        .then((resp) => {
          console.log(resp);
          if (resp.status === "success") {
            showAlert("success", "Уведомление добавлено");
            clearForm();
          } else {
            showAlert("error", resp.message);
          }

          setIsShowLoader(false);
        })
        .catch((err) => {
          showAlert("error", "Ошибка на сервере");
          setIsShowLoader(false);
        });
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Helmet>
        <title>Добавление уведомления</title>
      </Helmet>
      <Box sx={{ py: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Container>
      </Box>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          {isShowLoader && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ffffff",
                zIndex: 5,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <form>
            <Card>
              <CardHeader title="Добавить уведомление" />
              <Divider />
              <CardContent sx={{ position: "relative" }}>
                <TextField
                  fullWidth
                  label="Заголовок *"
                  margin="normal"
                  name="title"
                  onChange={handleChange}
                  type="text"
                  value={values.title}
                  variant="outlined"
                  error={errors.title}
                />
                <TextField
                  fullWidth
                  label="Описание"
                  margin="normal"
                  name="description"
                  onChange={handleChange}
                  type="text"
                  value={values.description}
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  label="Стоимость"
                  margin="normal"
                  name="cost"
                  onChange={handleChange}
                  type="number"
                  value={values.cost}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  variant="outlined"
                  sx={{mb:3}}
                />
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ru"
                >
                  <DatePicker
                    minDate={dayjs(Date.now() - 24 * 3600 * 1000)}
                    maxDate={dayjs("2100-12-31")}
                    sx={{ minWidth: 300 }}
                    ampm={false}
                    variant="static"
                    inputFormat="DD.MM.YYYY"
                    name="paymentDate"
                    label="Дата оплаты"
                    renderInput={(props) => <TextField {...props} />}
                    value={values.paymentDate}
                    onChange={(newValue) => {
                      setValues({
                        ...values,
                        paymentDate: newValue,
                      });
                    }}
                  />
                </LocalizationProvider>
                <Alert
                  severity={alert.type}
                  style={{ display: alert.isVisible ? "flex" : "none" }}
                >
                  {alert.txt}
                </Alert>
              </CardContent>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submit}
                  disabled={submitDisabled}
                >
                  Добавить
                </Button>
              </Box>
            </Card>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default ProjectNotificationAdd;
