import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    Alert
} from '@material-ui/core';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';

const UserSetPassForm = (props) => {
    const { id } = props;
    const [values, setValues] = useState({
      password: '',
      confirm: ''
    });
    const [passwordError, setPasswordError] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [alert, setAlert] = useState({
      txt: '',
      isVisible: false,
      type: 'error'
    });

    const handleChange = (event) => {
        if (event.target.name === 'password') {
          setPasswordError(false);
        }

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const showAlert = (type, text) => {
      setAlert({
        txt: text,
        type,
        isVisible: true
      });

      setTimeout(() => {
        setAlert({
          txt: text,
          type,
          isVisible: false
        });

        setSubmitDisabled(false);
      }, 1400);
    };

    const validate = () => {
      let validComplete = true;

      if (values.password.length < 5) {
        setPasswordError(true);
        validComplete = false;
      } else if (values.password !== values.confirm) {
        validComplete = false;
        setSubmitDisabled(true);
        showAlert('error', 'Passwords are different');
      }

      return validComplete;
    };

    const submit = () => {
      if (validate()) {
        let data = new FormData();
        data.append('password', values.password);
        setSubmitDisabled(true);

        RequestService.put(`users/${id}`, {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        }, data, false)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'User password edited');
          } else {
            showAlert('error', 'Error occured');
          }
        })
        .catch((err) => {
          showAlert('error', 'Error occured');
        });
      }
    };

    return (
      <form>
        <Box sx={{ pt: 3 }}>
            <Card>
              <CardHeader
                title="Password"
                subheader="Set password"
              />
              <Divider />
              <CardContent>
                  <TextField
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                    error={passwordError}
                    helperText={passwordError && "Password shouldn't be shorter than 5 characters"}
                  />
                  <TextField
                    fullWidth
                    label="Confirm password"
                    margin="normal"
                    name="confirm"
                    onChange={handleChange}
                    type="password"
                    value={values.confirm}
                    variant="outlined"
                    error={passwordError}
                  />
                  <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                    {alert.txt}
                  </Alert>
              </CardContent>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                      Редактировать
                  </Button>
              </Box>
            </Card>
        </Box>
      </form>
    );
};

UserSetPassForm.propTypes = {
  id: PropTypes.string.isRequired
};

export default UserSetPassForm;
