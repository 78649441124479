import React, {useState, useEffect} from 'react';
import {
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    CircularProgress,
    Alert,
    Container, InputLabel, Select, MenuItem, FormControl, Typography
} from '@material-ui/core';
import {useNavigate, useParams} from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Helmet from 'react-helmet';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';

const SecretKeyEdit = (props) => {
    const [values, setValues] = useState({
        key: '',
    });
    const [errors, setErrors] = useState({
        key: false,
    });

    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const navigate = useNavigate();

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text, hideThen = true) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        if (hideThen) {
            setTimeout(() => {
                setAlert({
                    txt: text,
                    type,
                    isVisible: false
                });

                setSubmitDisabled(false);
            }, 1400);
        }
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.key?.length === "") {
            validComplete = false;
            formErrors.name = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (validate()) {
            setIsShowLoader(true);
            setSubmitDisabled(true);

            const payload = {
                key: values.key,
            };

            RequestService.put(`secret`, {
                Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }, payload)
                .then((resp) => {
                    setIsShowLoader(false);

                    if (resp.status === 'success') {
                        showAlert('success', 'Секретный ключ отредактирован');
                    } else {
                        showAlert('error', resp.message);
                    }
                })
                .catch((err) => {
                    setIsShowLoader(false);
                    showAlert('error', 'Произошла ошибка на сервере');
                });
        }
    };

    useEffect(() => {

        RequestService.get(`secret`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
            .then((resp) => {
                if (resp.status === 'success') {
                    let secretData = resp.data.secretKey;

                    let vals = {
                        key: secretData.key || '',
                    };

                    setValues(vals);
                } else {
                    showAlert('error', 'Ошибка, данные не загружены', false);
                }
            })
            .catch((err) => {
                showAlert('error', 'Ошибка сервера, данные не загружены', false);
            });

    }, []);

    return (
        <>
            <Helmet>
                <title>Редактирование секретного ключа</title>
            </Helmet>
            <Box sx={{py: 2}}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                </Container>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%'}}>
                <Container maxWidth={false}>
                    <form>
                        <Card>
                            <CardHeader
                                title="Редактировать секретный ключ"
                            />
                            <Divider/>
                            <CardContent sx={{position: 'relative'}}>
                                {isShowLoader && (
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#ffffff',
                                        zIndex: 5
                                    }}
                                    >
                                        <CircularProgress/>
                                    </Box>
                                )}
                            <Typography>Данный ключ используется для получения данных из внешних api.</Typography>
                            <Typography color="gray" sx={{
                                mb: 1
                            }}>Необходимо его передавать в HEADERS парметром secret</Typography>

                                <TextField
                                    fullWidth
                                    label="Секретный ключ"
                                    margin="normal"
                                    name="key"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.key}
                                    variant="outlined"
                                    error={errors.key}
                                />
                                <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                    {alert.txt}
                                </Alert>
                            </CardContent>
                            <Divider/>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={submit}
                                    disabled={submitDisabled}
                                >
                                    Редактировать
                                </Button>
                            </Box>
                        </Card>
                    </form>
                </Container>
            </Box>
        </>
    );
};

export default SecretKeyEdit;
