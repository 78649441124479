import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
    Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import moment from 'moment';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        padding: 10
    },
    wrapAvatar: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex'
    },
    wrapInner: {
        flexDirection: 'row',
        display: 'flex',
    },
    label: {
        width: 200,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: 10,
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto'
    },
    panel: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#5664d21c'
    },
    panelInCareington: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#79b3ff1c',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    }
});

const ServerCheckInfo = () => {
    const { id } = useParams();

    const [result, setResult] = useState({});
    const [requestError, setRequestError] = useState(false);

    const styles = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        RequestService.get(`checkServer/${id}`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
            .then((resp) => {
                if (resp.status === 'success') {
                    setResult(resp.data.server);
                } else {
                    setRequestError(true);
                }
            })
            .catch((err) => {
                setRequestError(true);
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>Пользователь</title>
            </Helmet>
            <Box sx={{ py: 2 }}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Card>
                        <CardContent sx={{ p: 3 }}>
                            <Alert severity="error" style={{ display: requestError ? 'flex' : 'none', marginBottom: '16px' }}>
                                Ошибка загрузки данных
                            </Alert>
                            <div className={styles.wrapAvatar}>
                                <div>
                                    <div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Id:
                                            </div>
                                            <div className={styles.text}>
                                                {result?._id || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Name:
                                            </div>
                                            <div className={styles.text}>
                                                {result?.name || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Description:
                                            </div>
                                            <div className={styles.text}>
                                                {result?.description || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Project:
                                            </div>
                                            <div className={styles.text}>
                                                {result?.projectName || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                User ID:
                                            </div>
                                            <div className={styles.text}>
                                                {result?.user || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Interval:
                                            </div>
                                            <div className={styles.text}>
                                                {result?.interval || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Status:
                                            </div>
                                            <div className={styles.text}>
                                                {result?.status || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Is deleted:
                                            </div>
                                            <div className={styles.text}>
                                                {result?.isDeleted === false ? "false" : "true"}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Created at:
                                            </div>
                                            <div className={styles.text}>
                                                {moment(result.createdAt).format('HH:mm, DD.MM.YYYY') || '---'}
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Emails:
                                            </div>
                                            <div className={styles.text}>
                                                {
                                                    result?.emailsNotification?.map((item) =>
                                                        <div>
                                                            {item}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.wrap}>
                                            <div className={styles.label}>
                                                Addresses:
                                            </div>
                                            <div className={styles.text}>
                                                {
                                                    result?.address?.map((item) =>
                                                        <div>
                                                            {item}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                        <Divider />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                            <RouterLink to={`/app/user/edit/${id}`}>
                                <Button color="primary" variant="contained">
                                    Edit
                                </Button>
                            </RouterLink>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default ServerCheckInfo;
