import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';

const AccountProfileDetails = (props) => {
  const [values, setValues] = useState({
    email: '',
    login: '',
    website: '',
    company: '',
    contact: ''
  });

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [subject, setSubject] = useState('');
  const [dataBeforeChange, setDataBeforeChange] = useState({});

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text, doReload = true) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);

      if (doReload) {
        window.location.reload();
      }
    }, 1400);
  };

  const onSubmit = async () => {
    setSubmitDisabled(true);

    const payload = {};

    if (values.email !== dataBeforeChange.email) {
      payload.email = values.email;
    }

    if (values.login !== dataBeforeChange.login) {
      payload.login = values.login;
    }

    if (values.website !== dataBeforeChange.website) {
      payload.website = values.website;
    }

    if (values.contact !== dataBeforeChange.contact) {
      payload.contact = values.contact;
    }

    if (subject !== dataBeforeChange.subject) {
      payload.subject = subject == 'Company' ? true : false;
    }

    if (subject === 'Company' && values.company !== dataBeforeChange.company) {
      payload.company = values.company;
    }

    let data = new FormData();
    for (let key in payload) {
      data.append(key, payload[key]);
    }

    let resp = await RequestService.put('api/user', {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`
    }, data, false);

    if (resp.status === 'success') {
      showAlert('success', 'User edited');
    } else {
      showAlert('error', 'Error occured', false);

      // if (resp.message === 'Пользователь с таким email или login уже существует') {
      //   showAlert('error', 'User with such email or login already exists');
      // } else {
      //   showAlert('error', 'Error occured');
      // }
    }
};

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    // RequestService.get('api/user', {
    //   Authorization: `Bearer ${TokenStorage.getAccessToken()}`
    // })
    // .then((resp) => {
    //   if (resp.status === 'success') { 
    //     const userData = resp.data.user;
    //     const vals = {
    //       email: userData.email,
    //       login: userData.login || '',
    //       website: userData.website || '',
    //       contact: userData.contact || '',
    //       company: ''
    //     };
    //     const beforeChange = {
    //       email: userData.email,
    //       login: userData.login || '',
    //       website: userData.website || '',
    //       contact: userData.contact || '',
    //       company: '',
    //       subject: userData.subject == 1 ? 'Company' : 'Individual'
    //     };

    //     if (userData.subject === 1) {
    //       vals.company = userData.company;
    //       beforeChange.company = userData.company;
    //     }

    //     setDataBeforeChange(beforeChange);
    //     setSubject(userData.subject == 1 ? 'Company' : 'Individual');
    //     setValues(vals);
    //   }
    // });
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none', marginBottom: '12px' }}>
            {alert.txt}
          </Alert>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the login"
                label="Login"
                name="login"
                onChange={handleChange}
                required
                value={values.login}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Contact"
                name="contact"
                onChange={handleChange}
                value={values.contact}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Website"
                name="website"
                onChange={handleChange}
                value={values.website}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl fullWidth>
                <InputLabel id="userSubject">Subject</InputLabel>
                <Select
                  labelId="userSubject"
                  name="subject"
                  value={subject}
                  label="Subject"
                  onChange={(evt) => setSubject(evt.target.value)}
                >
                  <MenuItem value="Individual">Individual</MenuItem>
                  <MenuItem value="Company">Company</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              {
                subject === 'Company' &&
                <TextField
                  fullWidth
                  label="Company"
                  name="company"
                  required
                  onChange={handleChange}
                  value={values.company}
                  variant="outlined"
                />
              }
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={submitDisabled}
            onClick={onSubmit}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;
