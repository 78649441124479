import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TableFooter,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useConfirm } from "../../components/confirm";
import PerfectScrollbar from "react-perfect-scrollbar";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import PlatformSkelet from "../../skeletons/PlatformSkelet";

const PlatformList = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [platforms, setPlatforms] = useState([]);

  const [filterId, setFilterId] = useState("");
  const [filterName, setFilterName] = useState("");
  const [curSearchVal, setCurSearchVal] = useState("Id");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const confirm = useConfirm();

  const loadPlatforms = (setParams = true) => {
    setIsDataLoading(true);

    let endpoint = `platforms?page=${page + 1}&limit=${limit}`;

    if (setParams) {
      switch (curSearchVal) {
        case "Id":
          if (filterId !== "") {
            endpoint = `platforms?_id=${filterId}`;
          }
          break;

        case "Name":
          if (filterName !== "") {
            endpoint = `platforms?name=${filterName}`;
          }
          break;
      }
    }

    endpoint+="&sort=-createdAt"
    RequestService.get(endpoint, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          const platforms = resp.data.platforms;
          setIsDataLoaded(true);
          setPlatforms(platforms);
          setCount(resp.total);
        } else {
          setIsDataLoaded(false);
          setErrMsg("Произошла ошибка: данные не загружены");
        }

        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
        setIsDataLoaded(false);
        setErrMsg("Произошла ошибка: данные не загружены");
      });
  };

  const onDelete = (id) => {
    confirm({
      title: "Удаление",
      content: "Вы уверены, что хотите удалить платформу?",
      onConfirm: () => {
        RequestService.delete(`platforms/${id}`, {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
        }).then((resp) => {
          if (resp.status === "success") {
            loadPlatforms();
          }
        });
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleFilterId = (event) => {
    setFilterId(event.target.value);

    if (event.target.value === "") {
      loadPlatforms(false);
    }
  };

  const handleFilterName = (event) => {
    setFilterName(event.target.value);

    if (event.target.value === "") {
      loadPlatforms(false);
    }
  };

  const handleFilter = () => {
    loadPlatforms();
  };

  const searchInputChange = (newVal) => {
    setCurSearchVal(newVal);

    setFilterName("");
    setFilterId("");

    if (filterName !== "" || filterId !== "") {
      loadPlatforms(false);
    }
  };

  useEffect(() => {
    loadPlatforms();
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>Платформы</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          {isDataLoading ? (
            <PlatformSkelet />
          ) : (
            <>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box>
                  <Link to="/app/platforms/add">
                    <Button color="primary" variant="contained">
                      Добавить платформу
                    </Button>
                  </Link>
                </Box>

                <Box sx={{ ml: 1 }}>
                  <Link to="/app/platforms/deleted">
                    <Button color="primary" variant="contained">
                      Удаленные плафтормы
                    </Button>
                  </Link>
                </Box>
              </Box>
              {isDataLoaded ? (
                <Box sx={{ pt: 3 }}>
                  <Card>
                    <PerfectScrollbar>
                      <Box sx={{ pt: 1, pb: 2, px: 2 }}>
                        <Box>
                          <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                            <InputLabel id="searchType">
                              По какому полю фильтровать список
                            </InputLabel>
                            <Select
                              labelId="searchType"
                              name="searchValue"
                              value={curSearchVal}
                              label="По какому полю фильтровать список"
                              onChange={(evt) =>
                                searchInputChange(evt.target.value)
                              }
                            >
                              <MenuItem value="Id">Id</MenuItem>
                              <MenuItem value="Name">Название</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                        <Box
                          sx={{
                            mt: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            label="Id"
                            name="filterId"
                            onChange={handleFilterId}
                            type="text"
                            value={filterId}
                            variant="outlined"
                            fullWidth
                            sx={{
                              mr: 2,
                              display: curSearchVal === "Id" ? "block" : "none",
                            }}
                          />

                          <TextField
                            label="Название"
                            name="filterName"
                            onChange={handleFilterName}
                            type="text"
                            value={filterName}
                            variant="outlined"
                            fullWidth
                            sx={{
                              mr: 2,
                              display:
                                curSearchVal === "Name" ? "block" : "none",
                            }}
                          />

                          <Button
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={handleFilter}
                          >
                            Применить
                          </Button>
                        </Box>
                      </Box>
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Название</TableCell>
                            <TableCell>Описание</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {platforms.map((platform, ind) => (
                            <TableRow hover key={ind}>
                              <TableCell
                                style={{
                                  width: "130px",
                                  minWidth: "90px",
                                  wordBreak: "break-all",
                                }}
                              >
                                {platform._id}
                              </TableCell>
                              <TableCell>{platform.name}</TableCell>
                              <TableCell>{platform.description}</TableCell>
                              <TableCell>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Box>
                                    <Link
                                      to={`/app/platforms/updates/${platform._id}`}
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                      >
                                        Лог
                                      </Button>
                                    </Link>
                                  </Box>
                                  <Box sx={{ ml: 1 }}>
                                    <Link
                                      to={`/app/platforms/edit/${platform._id}`}
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                      >
                                        Ред.
                                      </Button>
                                    </Link>
                                  </Box>
                                  <Box sx={{ ml: 1 }}>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="small"
                                      onClick={() => onDelete(platform._id)}
                                    >
                                      Удалить
                                    </Button>
                                  </Box>
                                  <Box sx={{ ml: 1 }}>
                                    <Link
                                      to={`/app/platforms/tarrifs/${platform._id}`}
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                      >
                                        Тарифы
                                      </Button>
                                    </Link>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[10, 20, 30, 40, 50]}
                              colSpan={7}
                              count={count}
                              rowsPerPage={limit}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeLimit}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </PerfectScrollbar>
                  </Card>
                </Box>
              ) : (
                <Typography variant="h3" style={{ marginTop: "14px" }}>
                  {errMsg}
                </Typography>
              )}
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PlatformList;
