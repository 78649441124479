import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button,
    Divider
} from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import PlatformSkelet from '../../skeletons/PlatformSkelet';

const PlatformTariffs = () => {
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const [platfromTarrifs, setPlatformTarrifs] = useState([]);
    const [platfromName, setPlatformName] = useState([]);

    const { id } = useParams();
    const navigate = useNavigate();

    const loadPlatformTarrifs = () => {
        setIsDataLoading(true);

        RequestService.get(`platforms/${id}/tariffs`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                const tariffs = resp.data.tariffs;

                if (tariffs.length > 0) {
                    setIsDataLoaded(true);

                    RequestService.get(`platforms/${id}`, {
                        Authorization: `Bearer ${TokenStorage.getAccessToken()}`
                    })
                    .then((resp) => {
                        if (resp.status === 'success') {
                            const platform = resp.data.platform;
                            setPlatformName(`${platform.name} (ID: ${platform._id})`);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                } else {
                    setIsDataLoaded(false);
                    setErrMsg('Данные о тарифах для данной платформы отсутствуют');
                }

                setPlatformTarrifs(tariffs);
            } else {
                setIsDataLoaded(false);
                setErrMsg('Произошла ошибка: данные не загружены');
            }

            setIsDataLoading(false);
        })
        .catch((err) => {
            setIsDataLoading(false);
            setIsDataLoaded(false);
            setErrMsg('Произошла ошибка: данные не загружены');
        });
    };

    useEffect(() => {
        loadPlatformTarrifs();
    }, []);

    return (
        <>
            <Helmet>
                <title>Тарифы платформы</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <PlatformSkelet />
                            :
                            isDataLoaded ?
                                <Box sx={{ pt: 3 }}>
                                    <Box sx={{ py: 1 }}>
                                        <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                            Назад
                                        </Button>
                                    </Box>
                                    <Card>
                                        <Box sx={{ py: 2, pl: 2 }}>
                                            <Typography>
                                                Тарифы платформы {platfromName}
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        <Table sx={{ py: 2 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Название
                                                    </TableCell>
                                                    <TableCell>
                                                        Описание
                                                    </TableCell>
                                                    <TableCell>
                                                        Цена
                                                    </TableCell>
                                                    <TableCell>
                                                        Срок действия
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {platfromTarrifs.map((tariff, ind) => (
                                                    <TableRow hover key={ind}>
                                                        <TableCell>
                                                            {tariff._id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {tariff.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {tariff.description}
                                                        </TableCell>
                                                        <TableCell>
                                                            {tariff.price}
                                                        </TableCell>
                                                        <TableCell>
                                                            {tariff.duration}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Card>
                                </Box>
                                :
                                <Typography variant="h3" style={{ marginTop: '14px' }}>
                                    {errMsg}
                                </Typography>
                    }
                </Container>
            </Box>
        </>
    )
};

export default PlatformTariffs;
