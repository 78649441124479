import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TableFooter,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useConfirm } from "../../components/confirm";
import PerfectScrollbar from "react-perfect-scrollbar";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import PlatformSkelet from "../../skeletons/PlatformSkelet";
import { useParams, useNavigate } from "react-router-dom";
import { ChevronLeft } from "react-feather";

const PlatformUpdateList = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [platformUpdates, setPlatformUpdates] = useState([]);
  const [platform, setPlatform] = useState({});

  const confirm = useConfirm();

  const loadPlatformUpdates = (setParams = true) => {
    setIsDataLoading(true);

    let endpoint = `platforms/updates?platform=${id}`;

    RequestService.get(endpoint, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          console.log(resp.data);
          const platformUpdates = resp.data.platformUpdates;
          setIsDataLoaded(true);
          setPlatformUpdates(platformUpdates);
        } else {
          setIsDataLoaded(false);
          setErrMsg("Произошла ошибка: данные не загружены");
        }

        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
        setIsDataLoaded(false);
        setErrMsg("Произошла ошибка: данные не загружены");
      });
  };

  const loadPlatform = (setParams = true) => {
    setIsDataLoading(true);

    let endpoint = `platforms/${id}`;

    RequestService.get(endpoint, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          console.log(resp.data);
          const platform = resp.data.platform;
          setIsDataLoaded(true);
          setPlatform(platform);
        } else {
          setIsDataLoaded(false);
          setErrMsg("Произошла ошибка: данные не загружены");
        }

        setIsDataLoading(false);
      })
      .catch((err) => {
        setIsDataLoading(false);
        setIsDataLoaded(false);
        setErrMsg("Произошла ошибка: данные не загружены");
      });
  };

  const onDelete = (id) => {
    confirm({
      title: "Удаление",
      content: "Вы уверены, что хотите удалить обновление платформы?",
      onConfirm: () => {
        RequestService.delete(`platforms/updates/${id}`, {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
        }).then((resp) => {
          if (resp.status === "success") {
            loadPlatformUpdates();
          }
        });
      },
    });
  };

  useEffect(() => {
    loadPlatformUpdates();
    loadPlatform();
  }, []);

  return (
    <>
      <Helmet>
        <title>Обновления платформы</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          <Box sx={{ display: "flex", pb: 1, justifyContent: "space-between" }}>
            <Box sx={{}}>
              <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                Назад
              </Button>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box>
                <Link to={`/app/platforms/updates/add/${platform._id}`}>
                  <Button color="primary" variant="contained">
                    Добавить обновление
                  </Button>
                </Link>
              </Box>
              <Box sx={{ ml: 1 }}>
                <Link to={`/app/platforms/updates/deleted/${platform._id}`}>
                  <Button color="primary" variant="contained">
                    Удаленные обновления
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
          {isDataLoading ? (
            <PlatformSkelet />
          ) : isDataLoaded ? (
            <Box sx={{ pt: 3 }}>
              <Card>
                <Box sx={{ py: 2, pl: 2 }}>
                  <Typography>Обновления платформы {platform.name || ""}</Typography>
                </Box>
                <Divider />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Название</TableCell>
                      <TableCell>Описание</TableCell>
                      <TableCell>Дата</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {platformUpdates.map((update, ind) => (
                      <TableRow hover key={update._id}>
                        <TableCell>{update._id}</TableCell>
                        <TableCell>
                          {`${platform.name} ${update.version}`}
                        </TableCell>
                        <TableCell
                          style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            maxWidth: 300,
                          }}
                        >
                          {update.text.split("\n").map((p) => {
                            return (
                              <div
                                style={{
                                  whiteSpace: "normal",
                                  wordWrap: "break-word",
                                }}
                              >
                                {p}
                              </div>
                            );
                          })}
                        </TableCell>
                        <TableCell>
                          {new Date(update.createdAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Box>
                              <Link to={`/app/platforms/updates/edit/${update._id}`}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                >
                                  Ред.
                                </Button>
                              </Link>
                            </Box>
                            <Box sx={{ ml: 1 }}>
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() => onDelete(update._id)}
                              >
                                Удалить
                              </Button>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </Box>
          ) : (
            <Typography variant="h3" style={{ marginTop: "14px" }}>
              {errMsg}
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default PlatformUpdateList;
