import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link as LinkWrap,
  TextField,
  Typography
} from '@material-ui/core';
import RequestService from '../API/RequestService';
import TokenStorage from '../API/TokenStorage';

const Login = () => {
  const navigate = useNavigate();
  const [authError, setAuthError] = useState(false);
  const [errTxt, setErrTxt] = useState('');

  const showErrAlert = () => {
    setAuthError(true);

    setTimeout(() => {
      setAuthError(false);
    }, 1500);
  };

  const onSubmit = async (values) => {
    let payload = {
      login: values.login,
      password: values.password
    };

    try {

      let resp = await RequestService.post('auth/login', {
        'Content-Type': 'application/x-www-form-urlencoded'
      }, payload);

      if (resp.status === 'success') {
        let tokens = resp.data.tokens;
        TokenStorage.setAccessToken(tokens.access);
        TokenStorage.setRefreshToken(tokens.refresh);
        TokenStorage.setTokenExpire(tokens.expiredIn);
        TokenStorage.setTokenReceived(Date.now() / 1000);
  
        setTimeout(() => {
          navigate('/app/dashboard');
        });
      } else {

        if (resp.message === 'Переданы неверные данные') {
          setErrTxt('Wrong login or password');
        } else {
          setErrTxt('Error occured');
        }

        showErrAlert();
      }

    } catch(err) {
      setErrTxt('Server error');
      showErrAlert();
    }
    
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              login: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              login: Yup.string().max(255).required('Login is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.login && errors.login)}
                  fullWidth
                  helperText={touched.login && errors.login}
                  label="Login"
                  margin="normal"
                  name="login"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.login}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || authError}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                {authError ? <Typography style={{ color: 'red' }}>{errTxt}</Typography> : ''}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
