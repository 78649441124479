import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import PlatformSkelet from '../../skeletons/PlatformSkelet';

const ServerCheckDeleted = () => {
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [deletedData, setDeletedData] = useState([]);

    const navigate = useNavigate();

    const loadDeletedPlatforms = () => {
        setIsDataLoading(true);

        RequestService.get('checkServer/deleted', {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                const platforms = resp.data.servers;

                if (platforms.length > 0) {
                    setIsDataLoaded(true);
                } else {
                    setIsDataLoaded(false);
                    setErrMsg('У вас пока нет удалённых платформ');
                }

                setDeletedData(platforms);
            } else {
                setIsDataLoaded(false);
                setErrMsg('Произошла ошибка: данные не загружены');
            }

            setIsDataLoading(false);
        })
        .catch((err) => {
            setIsDataLoading(false);
            setIsDataLoaded(false);
            setErrMsg('Произошла ошибка: данные не загружены');
        });
    };

    const onRestore = (id) => {
        RequestService.get(`checkServer/restore/${id}`, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                loadDeletedPlatforms();
            }
        });
    };

    useEffect(() => {
        loadDeletedPlatforms();
    }, []);

    return (
        <>
            <Helmet>
                <title>Удалённые платформы</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <Box sx={{ pb: 1 }}>
                        <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                            Назад
                        </Button>
                    </Box>
                    {
                        isDataLoading ?
                            <PlatformSkelet />
                            :
                            isDataLoaded ?
                                <Box sx={{ pt: 3 }}>
                                    <Card>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Id
                                                    </TableCell>
                                                    <TableCell>
                                                        Название
                                                    </TableCell>
                                                    <TableCell>
                                                        Описание
                                                    </TableCell>
                                                    <TableCell>
                                                        Interval
                                                    </TableCell>
                                                    <TableCell>
                                                        status
                                                    </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {deletedData.map((item, ind) => (
                                                    <TableRow hover key={ind}>
                                                        <TableCell>
                                                            {item._id || "---"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.name || "---"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.description || "---"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.interval || "---"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.status || "---"}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    size="small"
                                                                    onClick={() => onRestore(item._id)}
                                                                >
                                                                    Восстановить
                                                                </Button>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Card>
                                </Box>
                                :
                                <Typography variant="h3" style={{ marginTop: '14px' }}>
                                    {errMsg}
                                </Typography>
                    }
                </Container>
            </Box>
        </>
    )
};

export default ServerCheckDeleted;
