import React, {useState} from 'react';
import {
    Alert,
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    CircularProgress,
    Container
} from '@material-ui/core';
import Helmet from 'react-helmet';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {useNavigate} from "react-router-dom";

const PlatfromAdd = () => {

    const navigate = useNavigate();

    const [values, setValues] = useState({
        name: '',
        description: '',
    });
    const [errors, setErrors] = useState({
        name: false
    });
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name.trim() === '') {
            validComplete = false;
            formErrors.name = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        let vals = {...values};

        for (let key in vals) {
            vals[key] = '';
        }

        setValues(vals);
    };

    const submit = async () => {
        if (validate()) {
            setIsShowLoader(true);
            setSubmitDisabled(true);

            const payload = {
                name: values.name,
            };

            if (values.description !== '') {
                payload.description = values.description;
            }

            RequestService.post('platforms', {
                Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }, payload)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Платформа добавлена');
                        clearForm();

                    } else {
                        showAlert('error', resp.message);
                    }

                    setIsShowLoader(false);
                })
                .catch((err) => {
                    showAlert('error', 'Server error');
                    setIsShowLoader(false);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Добавление платформы</title>
            </Helmet>

            <Box sx={{py: 2}}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <form>
                        <Card>
                            <CardHeader
                                title="Добавить платформу"
                            />
                            <Divider/>
                            <CardContent sx={{position: 'relative'}}>
                                {isShowLoader && (
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#ffffff',
                                        zIndex: 5
                                    }}
                                    >
                                        <CircularProgress/>
                                    </Box>
                                )}
                                <TextField
                                    fullWidth
                                    label="Название"
                                    margin="normal"
                                    name="name"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.name}
                                    variant="outlined"
                                    error={errors.name}
                                />
                                <TextField
                                    fullWidth
                                    label="Описание"
                                    margin="normal"
                                    name="description"
                                    onChange={handleChange}
                                    type="text"
                                    value={values.description}
                                    variant="outlined"
                                />
                                <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                    {alert.txt}
                                </Alert>
                            </CardContent>
                            <Divider/>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={submit}
                                    disabled={submitDisabled}
                                >
                                    Добавить
                                </Button>
                            </Box>
                        </Card>
                    </form>
                </Container>
            </Box>
        </>
    );
};

export default PlatfromAdd;
