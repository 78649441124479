import { useState, useEffect } from "react";
import {
  Box,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
  CircularProgress,
  Alert,
  Container,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Helmet from "react-helmet";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const ProjectNotificationEdit = (props) => {
  const { notificationId: id } = useParams();
  const [values, setValues] = useState({
    title: "",
    description: "",
    cost: 0,
    paymentDate: new Date()
  });
  const [errors, setErrors] = useState({
    title: false,
    description: false,
    cost: false,
    paymentDate: false,
  });

  const [isValidateEmail, setIsValidateEmail] = useState(true);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [autoRenewal, setAutoRenewal] = useState(false);
  const [chosenTariffId, setChosenTariffId] = useState("");
  const [tariffsData, setTariffsData] = useState([]);
  const [errMsg, setErrMsg] = useState("");

  const [alert, setAlert] = useState({
    txt: "",
    isVisible: false,
    type: "error",
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: false,
    });
  };

  const showAlert = (type, text, hideThen = true) => {
    setAlert({
      txt: text,
      type,
      isVisible: true,
    });

    if (hideThen) {
      setTimeout(() => {
        setAlert({
          txt: text,
          type,
          isVisible: false,
        });

        setSubmitDisabled(false);
      }, 1400);
    }
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.title.trim() === "") {
      validComplete = false;
      formErrors.title = true;
    }

    setErrors(formErrors);
    return validComplete;
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      const payload = {
        title: values.title,
        description: values.description,
        cost: values.cost,
        paymentDate: new Date(values.paymentDate).toJSON(),
      };

      RequestService.put(
        `projects/notifications/${id}`,
        {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        payload
      )
        .then((resp) => {
          setIsShowLoader(false);

          if (resp.status === "success") {
            showAlert("success", "Уведомлено отредактировано");
          } else {
            showAlert("error", resp.message);
          }
        })
        .catch((err) => {
          setIsShowLoader(false);
          showAlert("error", "Произошла ошибка на сервере");
        });
    }
  };

  useEffect(() => {
    RequestService.get(`projects/notifications/${id}`, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          let projectNotificationData = resp.data.projectNotification;

          let vals = {
            title: projectNotificationData.title || "",
            description: projectNotificationData.description || "",
            cost: projectNotificationData.cost || 0,
            paymentDate: projectNotificationData.paymentDate || new Date(),
          };

          setValues(vals);
        } else {
          showAlert("error", "Ошибка, данные не загружены", false);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка сервера, данные не загружены", false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Редактирование уведомления</title>
      </Helmet>
      <Box sx={{ py: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "background.default", minHeight: "100%" }}>
        <Container maxWidth={false}>
          <form>
            <Card>
              <CardHeader title="Редактировать уведомление" />
              <Divider />
              <CardContent sx={{ position: "relative" }}>
                <TextField
                  fullWidth
                  label="Заголовок *"
                  margin="normal"
                  name="title"
                  onChange={handleChange}
                  type="text"
                  value={values.title}
                  variant="outlined"
                  error={errors.title}
                />
                <TextField
                  fullWidth
                  label="Описание"
                  margin="normal"
                  name="description"
                  onChange={handleChange}
                  type="text"
                  value={values.description}
                  variant="outlined"
                />

                <TextField
                  fullWidth
                  label="Стоимость"
                  margin="normal"
                  name="cost"
                  onChange={handleChange}
                  type="number"
                  value={values.cost}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  variant="outlined"
                  sx={{mb:3}}
                />
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ru"
                >
                  <DatePicker
                    minDate={dayjs(Date.now() - 24 * 3600 * 1000)}
                    maxDate={dayjs("2100-12-31")}
                    sx={{ minWidth: 300 }}
                    ampm={false}
                    variant="static"
                    inputFormat="DD.MM.YYYY"
                    name="paymentDate"
                    label="Дата оплаты"
                    renderInput={(props) => <TextField {...props} />}
                    value={values.paymentDate}
                    onChange={(newValue) => {
                      setValues({
                        ...values,
                        paymentDate: newValue,
                      });
                    }}
                  />
                </LocalizationProvider>
                <Alert
                  severity={alert.type}
                  style={{ display: alert.isVisible ? "flex" : "none" }}
                >
                  {alert.txt}
                </Alert>
              </CardContent>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submit}
                  disabled={submitDisabled}
                >
                  Редактировать
                </Button>
              </Box>
            </Card>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default ProjectNotificationEdit;
