import React, {useState, useEffect} from 'react';
import {
    Alert,
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    CircularProgress,
    Container,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from '@material-ui/core';
import Helmet from 'react-helmet';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {useNavigate} from "react-router-dom";

const TariffAdd = () => {

    const navigate = useNavigate();

    const [values, setValues] = useState({
        name: '',
        price: '',
        duration: '1 мес',
        description: ''
    });
    const [errors, setErrors] = useState({
        name: false,
        price: false,
        duration: false
    });

    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [platformsData, setPlatformsData] = useState([]);
    const [chosenPlatformId, setChosenPlatformId] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        const isNumb = (inp) => {
            let res = true;

            for (let i = 0; i < inp.length; i++) {
                if (!(inp[i] >= '0' && inp[i] <= '9')) {
                    res = false;
                    break;
                }
            }

            return res;
        };

        if (values.name.trim() === '' || isNumb(values.name.trim())) {
            validComplete = false;
            formErrors.name = true;
        }

        if (!isNumb(values.price.trim())) {
            validComplete = false;
            formErrors.price = true;
        }

        if (!values.duration) {
            validComplete = false;
            formErrors.duration = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        let vals = {...values};

        for (let key in vals) {
            vals[key] = '';
        }

        setValues(vals);
    };

    const submit = async () => {
        if (validate()) {
            setIsShowLoader(true);
            setSubmitDisabled(true);

            const payload = {
                name: values.name.trim(),
                price: values.price.trim(),
                duration: values.duration.split("")[0],
                platform: chosenPlatformId
            };

            if (values.description.trim() !== '') {
                payload.description = values.description.trim();
            }

            RequestService.post('tariffs', {
                Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }, payload)
                .then((resp) => {
                    console.log(resp);
                    if (resp.status === 'success') {
                        showAlert('success', 'Тариф добавлен');
                        clearForm();
                    } else {
                        showAlert('error', resp.message);
                    }

                    setIsShowLoader(false);
                })
                .catch((err) => {
                    showAlert('error', 'Ошибка на сервере');
                    setIsShowLoader(false);
                });
        }
    };

    useEffect(() => {
        setIsShowLoader(true);

        RequestService.get('platforms?limit=0&sort=-createdAt', {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
            .then((resp) => {
                if (resp.status === 'success') {
                    const platforms = resp.data.platforms.filter((platfrom) => !platfrom.isDeleted);

                    if (platforms.length > 0) {
                        setChosenPlatformId(platforms[0]._id);

                        setPlatformsData(platforms.map((platform) => {
                            return {id: platform._id, name: platform.name};
                        }));
                    } else {
                        setErrMsg('Сначала добавьте платформу');
                    }
                } else {
                    setErrMsg('Произошла ошибка');
                }

                setIsShowLoader(false);
            })
            .catch((err) => {
                setIsShowLoader(false);
                setErrMsg('Произошла ошибка');
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>Добавление тарифа</title>
            </Helmet>
            <Box sx={{py: 2}}>
                <Container maxWidth={false}>
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                </Container>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {isShowLoader && (
                        <Box sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#ffffff',
                            zIndex: 5
                        }}
                        >
                            <CircularProgress/>
                        </Box>
                    )}
                    {
                        platformsData.length > 0 ?
                            <form>
                                <Card>
                                    <CardHeader
                                        title="Добавить тариф"
                                    />
                                    <Divider/>
                                    <CardContent sx={{position: 'relative'}}>
                                        <TextField
                                            fullWidth
                                            label="Название"
                                            margin="normal"
                                            name="name"
                                            onChange={handleChange}
                                            type="text"
                                            value={values.name}
                                            variant="outlined"
                                            error={errors.name}
                                            helperText={errors.name && 'Название не должно содержать только числа'}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Цена (руб)"
                                            margin="normal"
                                            name="price"
                                            onChange={handleChange}
                                            type="text"
                                            value={values.price}
                                            variant="outlined"
                                            error={errors.price}
                                            helperText={errors.price && 'Введите число'}
                                        />
                                        <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                            <InputLabel id="duration">Срок действия (мес)</InputLabel>
                                            <Select
                                                labelId="duration"
                                                name="duration"
                                                value={values.duration}
                                                label="Срок действия (мес)"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={"1 мес"}>
                                                    1 мес
                                                </MenuItem>
                                                <MenuItem value={"3 мес"}>
                                                    3 мес
                                                </MenuItem>
                                                <MenuItem value={"12 мес"}>
                                                    12 мес
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            label="Описание"
                                            margin="normal"
                                            name="description"
                                            onChange={handleChange}
                                            type="text"
                                            value={values.description}
                                            variant="outlined"
                                        />
                                        <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                                            <InputLabel id="platform">Платформа</InputLabel>
                                            <Select
                                                labelId="platform"
                                                name="platform"
                                                value={chosenPlatformId}
                                                label="Платформа"
                                                onChange={(evt) => setChosenPlatformId(evt.target.value)}
                                            >
                                                {
                                                    platformsData.map((item, ind) => {
                                                        return <MenuItem value={item.id} key={ind}>
                                                            {item.name} / {item.id}
                                                        </MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        <Alert severity={alert.type}
                                               style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                            {alert.txt}
                                        </Alert>
                                    </CardContent>
                                    <Divider/>
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={submit}
                                            disabled={submitDisabled}
                                        >
                                            Добавить
                                        </Button>
                                    </Box>
                                </Card>
                            </form>
                            :
                            <Typography variant="h2">{errMsg}</Typography>
                    }
                </Container>
            </Box>
        </>
    );
};

export default TariffAdd;
