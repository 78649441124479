import { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    CardContent,
    CardHeader,
    Card,
    Divider,
    Button,
    CircularProgress,
    Alert,
    Container
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Helmet from 'react-helmet';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';

const PlatformEdit = (props) => {
    const { id } = useParams();
    const [values, setValues] = useState({
      name: '',
      description: '',
    });
    const [errors, setErrors] = useState({
      name: false,
    });

    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
      txt: '',
      isVisible: false,
      type: 'error'
    });

    const navigate = useNavigate();

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
          ...errors,
          [event.target.name]: false
        });
    };

    const showAlert = (type, text, hideThen = true) => {
      setAlert({
        txt: text,
        type,
        isVisible: true
      });

      if (hideThen) {
        setTimeout(() => {
          setAlert({
            txt: text,
            type,
            isVisible: false
          });

          setSubmitDisabled(false);
        }, 1400);
      }
    };

    const validate = () => {
      let validComplete = true;
      let formErrors = {...errors};

      if (values.name.trim() === '') {
        validComplete = false;
        formErrors.name = true;
      }

      setErrors(formErrors);
      return validComplete;
    };

    const submit = async () => {
      if (validate()) {
        setIsShowLoader(true);
        setSubmitDisabled(true);

        const payload = {
          name: values.name,
          description: values.description
        };

        RequestService.put(`platforms/${id}`, {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }, payload)
        .then((resp) => {
          setIsShowLoader(false);

          if (resp.status === 'success') {
            showAlert('success', 'Платформа отредактирована');
          } else {
            showAlert('error', resp.message);
          }
        })
        .catch((err) => {
          setIsShowLoader(false);
          showAlert('error', 'Произошла ошибка на сервере');
        });
      }
    };

    useEffect(() => {

      RequestService.get(`platforms/${id}`, {
        Authorization: `Bearer ${TokenStorage.getAccessToken()}`
      })
      .then((resp) => {
        if (resp.status === 'success') {
          let platformData = resp.data.platform;

          let vals = {
            name: platformData.name,
            description: platformData.description
          };

          setValues(vals);
        } else {
          showAlert('error', 'Ошибка, данные не загружены', false);
        }
      })
      .catch((err) => {
        showAlert('error', 'Ошибка сервера, данные не загружены', false);
      });

    }, []);

    return (
      <>
        <Helmet>
            <title>Редактирование платформы</title>
        </Helmet>
        <Box sx={{ py: 2 }}>
            <Container maxWidth={false}>
                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                    Назад
                </Button>
            </Container>
        </Box>
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
            <Container maxWidth={false}>
                <form>
                    <Card>
                        <CardHeader
                            title="Редактировать платформу"
                        />
                        <Divider />
                        <CardContent sx={{ position: 'relative' }}>
                            {isShowLoader && (
                                <Box sx={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: '#ffffff',
                                  zIndex: 5
                                }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                            <TextField
                                fullWidth
                                label="Название"
                                margin="normal"
                                name="name"
                                onChange={handleChange}
                                type="text"
                                value={values.name}
                                variant="outlined"
                                error={errors.name}
                            />
                            <TextField
                                fullWidth
                                label="Описание"
                                margin="normal"
                                name="description"
                                onChange={handleChange}
                                type="text"
                                value={values.description}
                                variant="outlined"
                            />
                            <Alert severity={alert.type} style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                {alert.txt}
                            </Alert>
                        </CardContent>
                        <Divider />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={submit}
                                disabled={submitDisabled}
                            >
                                Редактировать
                            </Button>
                        </Box>
                    </Card>
                </form>
            </Container>
        </Box>
      </>
    );
};

export default PlatformEdit;
