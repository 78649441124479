export default function firstLetterToUpperCase(str) {
    let res = '';

    if (str) {
        let chars = str.split('');
        chars[0] = chars[0].toUpperCase();
        res = chars.join('');
    }

    return res;
}