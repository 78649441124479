import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  Code as CodeIcon,
  List as ListIcon,
  Settings as SettingsIcon,
  CreditCard as PaymentIcon,
  Info as InfoIcon, Server, Key
} from 'react-feather';
import NavItem from './NavItem';


const itemsDesign = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/users',
    icon: UsersIcon,
    title: 'Пользователи'
  }
];

const itemsSystem = [
  {
    href: '/app/platforms',
    icon: CodeIcon,
    title: 'Платформы'
  },
  {
    href: '/app/tariffs',
    icon: ListIcon,
    title: 'Тарифы'
  },
  {
    href: '/app/projects',
    icon: SettingsIcon,
    title: 'Проекты'
  },
  {
    href: '/app/payments',
    icon: PaymentIcon,
    title: 'Платежи'
  },
  {
    href: '/app/server-check',
    icon: Server,
    title: 'Сервера'
  },
];

const itemsData = [
  {
    href: '/app/logs',
    icon: InfoIcon,
    title: 'Логи'
  },
  {
    href: '/app/secret',
    icon: Key,
    title: 'Ключи'
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const [userAva, setUserAva] = useState('');
  const [userLogin, setUserLogin] = useState('Admin');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    // RequestService.get('api/user', {
    //   Authorization: `Bearer ${TokenStorage.getAccessToken()}`
    // })
    // .then((resp) => {
    //   if (resp.status === 'success') {
    //     let userData = resp.data.user;
    //     setUserAva(`/uploads/${userData.avatar}`);
    //     setUserLogin(userData.login);
    //   }
    // });
  }, []);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={userAva}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {userLogin}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ px: 2 }}>
        <List>
          {itemsDesign.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ px: 2 }}>
        <List>
          {itemsSystem.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ px: 2 }}>
        <List>
          {itemsData.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 210
            }
          }}
        >
          {content}
        </Drawer>
      </Box>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 180,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
