import { useState, useEffect } from "react";
import {
  Box,
  TextField,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
  CircularProgress,
  Alert,
  Container,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Helmet from "react-helmet";
import RequestService from "../../API/RequestService";
import TokenStorage from "../../API/TokenStorage";

const ProjectEdit = (props) => {
  const { id } = useParams();
  const [values, setValues] = useState({
    name: "",
    email: "",
    description: "",
    clientId: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    price: false,
    duration: false,
  });

  const [isValidateEmail, setIsValidateEmail] = useState(true);
  const [isShowLoader, setIsShowLoader] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [autoRenewal, setAutoRenewal] = useState(false);

  const [chosenTariffId, setChosenTariffId] = useState("");
  const [filteredTariffsData, setFilteredTariffsData] = useState([]);
  const [tariffsData, setTariffsData] = useState([]);

  const [platformsData, setPlatformsData] = useState([]);
  const [chosenPlatformId, setChosenPlatformId] = useState("");

  const [errMsg, setErrMsg] = useState("");

  const [alert, setAlert] = useState({
    txt: "",
    isVisible: false,
    type: "error",
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: false,
    });
  };

  const handleChangeEmail = (event) => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setIsValidateEmail(!!event.target.value.match(reg));
    setValues({
      ...values,
      email: event.target.value.trim(),
    });
  };

  const showAlert = (type, text, hideThen = true) => {
    setAlert({
      txt: text,
      type,
      isVisible: true,
    });

    if (hideThen) {
      setTimeout(() => {
        setAlert({
          txt: text,
          type,
          isVisible: false,
        });

        setSubmitDisabled(false);
      }, 1400);
    }
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.name.trim() === "") {
      validComplete = false;
      formErrors.name = true;
    }

    if (!isValidateEmail || values.email.trim() === "") {
      validComplete = false;
      formErrors.email = true;
      setIsValidateEmail(false);
    }

    setErrors(formErrors);
    return validComplete;
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      const payload = {
        name: values.name,
        email: values.email,
        tariff: chosenTariffId == "Нет" ? "" : chosenTariffId,
        platform: chosenPlatformId,
        autoRenewal,
        clientId: values.clientId
      };

      if (values.description.trim() !== "") {
        payload.description = values.description;
      }

      RequestService.put(
        `projects/${id}`,
        {
          Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        payload
      )
        .then((resp) => {
          setIsShowLoader(false);

          if (resp.status === "success") {
            showAlert("success", "Проект отредактирован");
          } else {
            showAlert("error", resp.message);
          }
        })
        .catch((err) => {
          setIsShowLoader(false);
          showAlert("error", "Произошла ошибка на сервере");
        });
    }
  };

  useEffect(async () => {
    await RequestService.get("tariffs?limit=0&sort=-createdAt", {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          const tariffs = resp.data.tariffs.filter(
            (tariff) => !tariff.isDeleted
          );

          if (tariffs.length > 0) {
            setTariffsData([{ id: "", name: "Нет" }, ...tariffs]);
          } else {
            setErrMsg("Сначала добавьте тариф");
          }
        } else {
          setErrMsg("Произошла ошибка");
        }

        setIsShowLoader(false);
      })
      .catch((err) => {
        setIsShowLoader(false);
        setErrMsg("Произошла ошибка");
      });

    RequestService.get("platforms?limit=0&sort=-createdAt", {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          const platforms = resp.data.platforms.filter(
            (platfrom) => !platfrom.isDeleted
          );

          if (platforms.length > 0) {
            setPlatformsData(
              platforms.map((platform) => {
                return { id: platform._id, name: platform.name };
              })
            );
          } else {
            setErrMsg("Сначала добавьте платформу");
          }
        } else {
          setErrMsg("Произошла ошибка");
        }

        setIsShowLoader(false);
      })
      .catch((err) => {
        setIsShowLoader(false);
        setErrMsg("Произошла ошибка");
      });

    await RequestService.get(`projects/${id}`, {
      Authorization: `Bearer ${TokenStorage.getAccessToken()}`,
    })
      .then((resp) => {
        if (resp.status === "success") {
          let projectData = resp.data.project;

          let vals = {
            name: projectData.name || "",
            email: projectData.email || "",
            description: projectData.description || "",
            clientId: projectData.clientId || "",
          };

          setAutoRenewal(projectData.autoRenewal);
          setValues(vals);
          setChosenTariffId(projectData.tariff || "Нет");
          setChosenPlatformId(projectData.platform);
        } else {
          showAlert("error", "Ошибка, данные не загружены", false);
        }
      })
      .catch((err) => {
        showAlert("error", "Ошибка сервера, данные не загружены", false);
      });
  }, []);

  useEffect(() => {
    console.log("here");
    const filteredTariffs = tariffsData.filter(
      (tariff) => tariff.platform == chosenPlatformId
    );
    setFilteredTariffsData([{ id: "", name: "Нет" }, ...filteredTariffs]);
    setChosenTariffId("Нет");
  }, [chosenPlatformId]);

  return (
    <>
      <Helmet>
        <title>Редактирование проекта</title>
      </Helmet>
      <Box sx={{ py: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "background.default", minHeight: "100%" }}>
        <Container maxWidth={false}>
          <form>
            <Card>
              <CardHeader title="Редактировать проект" />
              <Divider />
              <CardContent sx={{ position: "relative" }}>
                {isShowLoader && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#ffffff",
                      zIndex: 5,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <TextField
                  fullWidth
                  label="Название *"
                  margin="normal"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                  error={errors.name}
                  helperText={
                    errors.name && "Название не должно содержать только числа"
                  }
                />
                <TextField
                  fullWidth
                  label="Email *"
                  margin="normal"
                  name="email"
                  onChange={handleChangeEmail}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  error={!isValidateEmail}
                />
                <TextField
                  fullWidth
                  label="Описание"
                  margin="normal"
                  name="description"
                  onChange={handleChange}
                  type="text"
                  value={values.description}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Идентификатор для кабинета клиентов"
                  margin="normal"
                  name="clientId"
                  onChange={handleChange}
                  type="text"
                  value={values.clientId}
                  variant="outlined"
                />
                <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                  <InputLabel id="platform">Платформа</InputLabel>
                  <Select
                    labelId="platform"
                    name="platform"
                    value={chosenPlatformId}
                    label="Платформа"
                    onChange={(evt) => setChosenPlatformId(evt.target.value)}
                  >
                    {platformsData.map((item, ind) => {
                      return (
                        <MenuItem value={item.id} key={ind}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                  <InputLabel id="tariff">Тариф</InputLabel>
                  <Select
                    labelId="tariff"
                    name="tariff"
                    value={chosenTariffId}
                    label="Тариф"
                    onChange={(evt) => {
                      setChosenTariffId(evt.target.value);
                    }}
                  >
                    {filteredTariffsData.map((item, ind) => {
                      return (
                        <MenuItem value={item._id || item.name} key={ind}>
                          {item.name}
                          {item.price != null && ` / Стоимость: ${item.price}`}
                          {item.duration && ` / ${item.duration} мес.`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                  <InputLabel id="tariff">Внутренний проект</InputLabel>
                  <Select
                    labelId="autoRenewal"
                    name="autoRenewal"
                    value={autoRenewal}
                    label="Внутренний проект"
                    onChange={(evt) => setAutoRenewal(evt.target.value)}
                  >
                    <MenuItem value={true}>Да</MenuItem>
                    <MenuItem value={false}>Нет</MenuItem>
                  </Select>
                </FormControl>
                <Alert
                  severity={alert.type}
                  style={{ display: alert.isVisible ? "flex" : "none" }}
                >
                  {alert.txt}
                </Alert>
              </CardContent>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submit}
                  disabled={submitDisabled}
                >
                  Редактировать
                </Button>
              </Box>
            </Card>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default ProjectEdit;
