import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button,
    TableFooter,
    TablePagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Divider
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useConfirm } from '../../components/confirm';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RequestService from '../../API/RequestService';
import TokenStorage from '../../API/TokenStorage';
import PlatformSkelet from '../../skeletons/PlatformSkelet';

const ProjectList = () => {
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [projects, setProjects] = useState([]);

    const [filterVals, setFilterVals] = useState({
        id: '',
        name: '',
        platfromId: '',
        tariffId: '',
        publicKey: ''
    });

    const [autoRenewal, setAutoRenewal] = useState('Без фильтра');
    const [filterActive, setFilterActive] = useState('Без фильтра');
    const [filterStatus, setFilterStatus] = useState('Без фильтра');
    const [curSearchVal, setCurSearchVal] = useState('Id');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);

    const confirm = useConfirm();

    const changeValue = (evt) => {
        setFilterVals({
            ...filterVals,
            [evt.target.name]: evt.target.value
        });

        if (evt.target.value === '') {
            loadProjects(false);
        }
    };

    const loadProjects = (setParams = true) => {
        setIsDataLoading(true);

        let endpoint = `projects?isDeleted=false&page=${page + 1}&limit=${limit}`;

        if (setParams) {
            switch (curSearchVal) {
                case 'Id':
                    if (filterVals.id !== '') {
                        endpoint = `projects?_id=${filterVals.id}`;
                    }
                break;

                case 'Name':
                    if (filterVals.name !== '') {
                        endpoint = `projects?name=${filterVals.name}`;
                    }
                break;

                case 'Platform id':
                    if (filterVals.platfromId !== '') {
                        endpoint = `projects?platform=${filterVals.platfromId}`;
                    }
                break;

                case 'Tariff id':
                    if (filterVals.tariffId !== '') {
                        endpoint = `projects?tariff=${filterVals.tariffId}`;
                    }
                break;

                case 'Public key':
                    if (filterVals.publicKey !== '') {
                        endpoint = `projects?publicKey=${filterVals.publicKey}`;
                    }
                break;

                case 'Auto renewal':
                    if (autoRenewal !== 'Без фильтра') {
                        endpoint = `projects?autoRenewal=${autoRenewal}`;
                    }
                break;

                case 'Active':
                    if (filterActive !== 'Без фильтра') {
                        endpoint = `projects?isActive=${filterActive}`;
                    }
                break;

                case 'Status':
                    if (filterStatus !== 'Без фильтра') {
                        endpoint = `projects?status=${filterStatus}`;
                    }
                break;
            }
        }

        endpoint += "&sort=-createdAt"
        RequestService.get(endpoint, {
            Authorization: `Bearer ${TokenStorage.getAccessToken()}`
        })
        .then((resp) => {
            if (resp.status === 'success') {
                const projects = resp.data.projects;
                console.log(resp.data)
                setIsDataLoaded(true);
                setProjects(projects);
                setCount(resp.total);
            } else {
                setIsDataLoaded(false);
                setErrMsg('Произошла ошибка: данные не загружены');
            }

            setIsDataLoading(false);
        })
        .catch((err) => {
            setIsDataLoading(false);
            setIsDataLoaded(false);
            setErrMsg('Произошла ошибка: данные не загружены');
        });
    };

    const onDelete = (id) => {
        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить проект?',
            onConfirm: () => {
                RequestService.delete(`projects/${id}`, {
                    Authorization: `Bearer ${TokenStorage.getAccessToken()}`
                })
                .then((resp) => {
                    if (resp.status === 'success') {
                        loadProjects();
                    }
                });
            }
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilter = () => {
        loadProjects();
    }

    const searchInputChange = (newVal) => {
        setCurSearchVal(newVal);

        setFilterVals({
            id: '',
            name: '',
            platfromId: '',
            tariffId: '',
            publicKey: ''
        });

        setAutoRenewal('Без фильтра');
        setFilterActive('Без фильтра');
        setFilterStatus('Без фильтра');

        let isAnyFilter = false;

        Object.values(filterVals).forEach((value) => {
            if (value !== '') {
                isAnyFilter = true;
            }
        });

        if (isAnyFilter || autoRenewal !== 'Без фильтра' || filterActive !== 'Без фильтра' || filterStatus !== 'Без фильтра') {
            loadProjects(false);
        }
    };

    useEffect(() => {
        loadProjects();
    }, [page, limit]);

    return (
        <>
            <Helmet>
                <title>Проекты</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <PlatformSkelet />
                            :
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box>
                                        <Link to="/app/projects/add">
                                            <Button color="primary" variant="contained">
                                                Добавить проект
                                            </Button>
                                        </Link>
                                    </Box>

                                    <Box sx={{ ml: 1 }}>
                                        <Link to="/app/projects/deleted">
                                            <Button color="primary" variant="contained">
                                                Удаленные проекты
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                {
                                    isDataLoaded ?
                                        <Box sx={{ pt: 3 }}>
                                            <Card>
                                                <PerfectScrollbar>
                                                    <Box sx={{ pt: 1, pb: 2, px: 2 }}>
                                                        <Box>
                                                            <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                                                <InputLabel id="searchType">По какому полю фильтровать список</InputLabel>
                                                                <Select
                                                                  labelId="searchType"
                                                                  name="searchValue"
                                                                  value={curSearchVal}
                                                                  label="По какому полю фильтровать список"
                                                                  onChange={(evt) => searchInputChange(evt.target.value)}
                                                                >
                                                                    <MenuItem value="Id">Id</MenuItem>
                                                                    <MenuItem value="Name">Название</MenuItem>
                                                                    <MenuItem value="Platform id">Id платформы</MenuItem>
                                                                    <MenuItem value="Tariff id">Id тарифа</MenuItem>
                                                                    <MenuItem value="Public key">Пуб. ключ</MenuItem>
                                                                    <MenuItem value="Auto renewal">Внутренний</MenuItem>
                                                                    <MenuItem value="Active">Активный</MenuItem>
                                                                    <MenuItem value="Status">Статус</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>

                                                        <Box sx={{
                                                                    mt: 1,
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center'
                                                                }}
                                                        >
                                                            <TextField label="Id"
                                                                       name="id"
                                                                       onChange={changeValue}
                                                                       type="text"
                                                                       value={filterVals.id}
                                                                       variant="outlined"
                                                                       fullWidth
                                                                       sx={{ mr: 2, display: curSearchVal === 'Id' ? 'block' : 'none' }}
                                                            />

                                                            <TextField label="Название"
                                                                    name="name"
                                                                    onChange={changeValue}
                                                                    type="text"
                                                                    value={filterVals.name}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ mr: 2, display: curSearchVal === 'Name' ? 'block' : 'none' }}
                                                            />

                                                            <TextField label="Id платформы"
                                                                    name="platfromId"
                                                                    onChange={changeValue}
                                                                    type="text"
                                                                    value={filterVals.platfromId}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ mr: 2, display: curSearchVal === 'Platform id' ? 'block' : 'none' }}
                                                            />

                                                            <TextField label="Id тарифа"
                                                                    name="tariffId"
                                                                    onChange={changeValue}
                                                                    type="text"
                                                                    value={filterVals.tariffId}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ mr: 2, display: curSearchVal === 'Tariff id' ? 'block' : 'none' }}
                                                            />

                                                            <TextField label="Пуб. ключ"
                                                                    name="publicKey"
                                                                    onChange={changeValue}
                                                                    type="text"
                                                                    value={filterVals.publicKey}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{ mr: 2, display: curSearchVal === 'Public key' ? 'block' : 'none' }}
                                                            />

                                                            <FormControl fullWidth sx={{ mr: 2, display: curSearchVal === 'Auto renewal' ? 'block' : 'none' }}>
                                                                <InputLabel id="autoRenewal">Внутренние проекты</InputLabel>
                                                                <Select
                                                                  labelId="autoRenewal"
                                                                  name="autoRenewal"
                                                                  value={autoRenewal}
                                                                  fullWidth
                                                                  label="Внутренний проект"
                                                                  onChange={(evt) => setAutoRenewal(evt.target.value)}
                                                                >
                                                                    <MenuItem value="Без фильтра">Без фильтра</MenuItem>
                                                                    <MenuItem value={true}>Да</MenuItem>
                                                                    <MenuItem value={false}>Нет</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                            <FormControl fullWidth sx={{ mr: 2, display: curSearchVal === 'Active' ? 'block' : 'none' }}>
                                                                <InputLabel id="activeProjects">Активные проекты</InputLabel>
                                                                <Select
                                                                  labelId="activeProjects"
                                                                  name="activeProjects"
                                                                  value={filterActive}
                                                                  fullWidth
                                                                  label="Активные проекты"
                                                                  onChange={(evt) => setFilterActive(evt.target.value)}
                                                                >
                                                                    <MenuItem value="Без фильтра">Без фильтра</MenuItem>
                                                                    <MenuItem value={true}>Да</MenuItem>
                                                                    <MenuItem value={false}>Нет</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                            <FormControl fullWidth sx={{ mr: 2, display: curSearchVal === 'Status' ? 'block' : 'none' }}>
                                                                <InputLabel id="statusProjects">Статус</InputLabel>
                                                                <Select
                                                                  labelId="statusProjects"
                                                                  name="statusProjects"
                                                                  value={filterStatus}
                                                                  fullWidth
                                                                  label="Статус"
                                                                  onChange={(evt) => setFilterStatus(evt.target.value)}
                                                                >
                                                                    <MenuItem value="Без фильтра">Без фильтра</MenuItem>
                                                                    <MenuItem value="выключен">Выключен</MenuItem>
                                                                    <MenuItem value="не оплачен">Не оплачен</MenuItem>
                                                                    <MenuItem value="не работает">Не работает</MenuItem>
                                                                    <MenuItem value="работает">Работает</MenuItem>
                                                                </Select>
                                                            </FormControl>

                                                            <Button
                                                              color="primary"
                                                              variant="contained"
                                                              size="large"
                                                              onClick={handleFilter}
                                                            >
                                                                Применить
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    <Divider />
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Id
                                                                </TableCell>
                                                                <TableCell>
                                                                    Название
                                                                </TableCell>
                                                                <TableCell>
                                                                    Тариф
                                                                </TableCell>
                                                                <TableCell>
                                                                    Платформа
                                                                </TableCell>
                                                                <TableCell>
                                                                    Пуб. ключ
                                                                </TableCell>
                                                                <TableCell>
                                                                    Внутренний
                                                                </TableCell>
                                                                <TableCell>
                                                                    Активный
                                                                </TableCell>
                                                                <TableCell>
                                                                    Статус
                                                                </TableCell>
                                                                <TableCell />
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {projects.map((project, ind) => (
                                                                <TableRow hover key={ind}>
                                                                    <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                                        {project._id}
                                                                    </TableCell>
                                                                    <TableCell style={{ maxWidth: '170px', wordBreak: 'break-all' }}>
                                                                        {project.name}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                                        {project.tariffrmName || "Нет"}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '130px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                                        {project.platformName}
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '190px', minWidth: '90px', wordBreak: 'break-all' }}>
                                                                        {project.publicKey}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {project.autoRenewal ? 'Да' : 'Нет'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {project.isActive ? 'Да' : 'Нет'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {project.status}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                            <Box>
                                                                                <Link to={`/app/project/${project._id}`}>
                                                                                    <Button
                                                                                      color="primary"
                                                                                      variant="contained"
                                                                                      size="small"
                                                                                    >
                                                                                        Инфо.
                                                                                    </Button>
                                                                                </Link>
                                                                            </Box>
                                                                            <Box sx={{ ml: 1 }}>
                                                                                <Link to={`/app/projects/edit/${project._id}`}>
                                                                                    <Button
                                                                                    color="primary"
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    >
                                                                                        Ред.
                                                                                    </Button>
                                                                                </Link>
                                                                            </Box>
                                                                            <Box sx={{ ml: 1 }}>
                                                                                <Button
                                                                                color="primary"
                                                                                variant="contained"
                                                                                size="small"
                                                                                onClick={() => onDelete(project._id)}
                                                                                >
                                                                                    Удалить
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                        <TableFooter>
                                                            <TableRow>
                                                                <TablePagination
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                                />
                                                            </TableRow>
                                                        </TableFooter>
                                                    </Table>
                                                </PerfectScrollbar>
                                            </Card>
                                        </Box>
                                        :
                                        <Typography variant="h3" style={{ marginTop: '14px' }}>
                                            {errMsg}
                                        </Typography>
                                }
                            </>
                    }
                </Container>
            </Box>
        </>
    )
};

export default ProjectList;
